<template>
<el-dialog title="登录" :visible.sync="show" :close-on-click-modal=false>
  <el-form>
    <el-form-item label="用户名">
      <el-input v-model="username" placeholder="用户名"></el-input>
    </el-form-item>
    <el-form-item label="密码">
      <el-input type="password" v-model="password" placeholder="密码" @keyup.enter.native="login"></el-input>
    </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="show = false">取 消</el-button>
    <el-button type="primary" @click="login">确 定</el-button>
  </div>
</el-dialog>
</template>
<script>
</script>
<script>
    import {
        md5
    } from '@/config/md5'
    import * as api from '@/config/api'
    import Store from '@/store'
    export default {
        name: 'loginDialog',
        data() {
            return {
                username: '',
                password: '',
                show: false
            }
        },
        watch: {
            show(val) {
                if (val === false) {
                    window.login = false
                }
            }
        },
        mounted() {

        },
        methods: {
            login() {
                if (this.username == '' || this.password == '') {
                    this.$message.error('请输入正确的账号或密码');
                    return
                }
                api.getLogin({
                    'user_name': this.username,
                    'password': md5(this.password)
                }, res => {

                    let store = {
                        'userName': res.data.real_name,
                        'userToken': res.data.token,
                        'userId': res.data.admin_id
                    }
                    window.localStorage.setItem('messageStore', JSON.stringify(store))

                    Store.commit('handleUserName', res.data.real_name)
                    Store.commit('handleUserId', res.data.admin_id)
                    Store.commit('handleUserToken', res.data.token)

                    if (res.code === 2019) {
                        api.getCommon()
                        this.$router.push({
                            path: '/modifyPwd'
                        })
                        return
                    }
                    api.getCommon(res => {
                        this.$confirm('是否要刷新页面', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            location.reload()
                        }).catch(() => {})
                    })
                    this.show = false
                })
            },

        }
    }
</script>
<style>
    /* .login {
        background-color: #343a40
    } */
    
    .login-box {
        width: 400px;
        height: 300px;
        background-color: rgba(234, 234, 234, .5);
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 5px;
        box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
    }
    
    .login-title {
        font-size: 40px;
        margin-top: 30px;
        -webkit-text-stroke: 1px #ffffff;
        color: transparent;
    }
    
    .login-from-box {
        width: 300px;
        margin: 20px auto;
    }
    
    .login-btn {
        width: 180px;
        height: 30px;
        font-size: 20px;
        font-weight: 400;
        margin: 0 auto;
        color: #fff;
        border: 0;
        border-radius: 15px;
        cursor: pointer;
        /* background-image: linear-gradient(to right, #e4afcb 0%, #b8cbb8 0%, #b8cbb8 0%, #e2c58b 30%, #c2ce9c 64%, #7edbdc 100%) */
    }
    
    .login-title:hover {
        color: #fff;
        -webkit-animation: Glow 1.5s ease infinite alternate;
        animation: Glow 1.5s ease infinite alternate;
    }
</style>