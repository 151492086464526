<template>
<div v-show="showExport">
    <el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false>
        <div class="title">推荐信息</div>
        <el-row>
            <el-form label-width="120px">
                <el-form-item label="主标题">
                    <el-input v-model="title" placeholder="请输入页面标题"></el-input>
                </el-form-item>
                <el-form-item label="副标题">
                    <el-input v-model="subtitle" placeholder="请输入页面标题"></el-input>
                </el-form-item>
                <el-form-item label="主图">
                    <upLoad dir="image/recommend" id="up" @url="imgUrl" v-model="main_img"></upLoad>
                </el-form-item>
                <el-form-item label="标签1">
                    <el-input v-model="label_1" placeholder="请输入页面标题"></el-input>
                </el-form-item>
                <el-form-item label="标签2">
                    <el-input v-model="label_2" placeholder="请输入页面标题"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio-group v-model="status">
                        <el-radio v-for="item in statusList" :label="item.type">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </el-row>
        <div class="title">跳转配置</div>
        <el-row>
            <el-form label-width="120px">
                 <el-form-item label="跳转类型">
                    <el-select v-model="redirect_type" placeholder="请选择">
                        <el-option
                        v-for="item in redirect_type_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="安卓跳转链接">
                    <el-input v-model="android_redirect_url" placeholder="请输入跳转链接"></el-input>
                </el-form-item>
                 <el-form-item label="ios跳转链接">
                    <el-input v-model="ios_redirect_url" placeholder="请输入跳转链接"></el-input>
                </el-form-item>
                 <el-form-item label="携带参数">
                    <el-input v-model="bind_params" placeholder="请输入携带参数"></el-input>
                </el-form-item>
                 <el-form-item label="跳转标题">
                    <el-input v-model="redirect_title" placeholder="请输入跳转标题"></el-input>
                </el-form-item>
                 <el-form-item label="版本号限制">
                    <el-radio-group v-model="version_limit_type">
                        <el-radio v-for="item in version_limit_list" :label="item.type">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                 <el-form-item label="版本号">
                    <el-input v-model="version" placeholder="请输入版本号"></el-input>
                </el-form-item>
                
                <el-form-item label="是否直接跳转">
                    <el-radio-group v-model="redirect_jump">
                        <el-radio v-for="item in is_redirect_jump" :label="item.type">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>


                <el-form-item label="审核模式">
                    <el-radio-group v-model="audit_mode">
                        <el-radio v-for="item in is_version" :label="item.type">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </el-row>
        <div class="title">推荐信息</div>
        <el-row>
            <el-form label-width="120px">
                <el-form-item label="匹配模式">
                    <el-radio-group v-model="match_mode">
                        <el-radio v-for="item in match_mode_list" :label="item.type">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="推荐位置">
                    <el-select v-model="position" multiple placeholder="请选择">
                        <el-option
                        v-for="item in arrpos"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="搜索关键字匹配">
                    <el-input v-model="keyword" placeholder="请输入搜索关键词,以‘,’隔开"></el-input>
                </el-form-item>
                <el-form-item label="商品标题/剪切板文案">
                    <el-input v-model="item_title" placeholder="请输入搜索关键词,以‘,’隔开"></el-input>
                </el-form-item>
                <el-form-item label="店铺名称">
                    <el-input v-model="shop_name" placeholder="请输入搜索关键词,以‘,’隔开"></el-input>
                </el-form-item>
                <el-form-item label="商品类目">
                    <el-input v-model="item_cate" placeholder="请输入搜索关键词,以‘,’隔开"></el-input>
                </el-form-item>
            </el-form>
        </el-row>
     <div slot="footer" class="dialog-footer">
         <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="primary">确 定</el-button>
    </div>
</el-dialog>
</div>
</template>
<script>
    import * as api from '@/config/api'
    export default {
        name: 'platformDialog',
        props: {
            showExport: Boolean,
            platform: Object
        },
        data() {
            return {
                dialog: false,
                ossToken: {},
                statusList: [{
                    'type': 1,
                    'name': "启用"
                }, {
                    'type': 2,
                    'name': "停用"
                }],
                is_redirect_jump: [{
                    type: 1,
                    name: '是'
                }, {
                    type: 2,
                    name: '否'
                }],
                is_version: [{
                    'type': 1,
                    'name': "显示"
                }, {
                    'type': 2,
                    'name': "隐藏"
                }],

                match_mode_list: [{
                    'type': 1,
                    'name': "正则"
                }, {
                    'type': 2,
                    'name': "模糊匹配(包含)"
                }],
                arrpos: [],
                arrposid: [],
                redirect_type_list: [],
                version_limit_list: [],
                id: '',
                title: '',
                subtitle: '',
                main_img: '',
                label_1: '',
                label_2: '',
                status: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
                bind_params: '',
                redirect_title: '',
                version_limit_type: '',
                version: '',
                redirect_jump: '',
                audit_mode: '',
                match_mode: '',
                keyword: '',
                position: [],
                item_title: '',
                shop_name: '',
                item_cate: '',
            }
        },
        watch: {
            platform(val) {
                console.log(val)
                this.id = val.id
                this.title = val.title
                this.subtitle = val.subtitle
                this.main_img = val.main_img
                this.label_1 = val.label_1
                this.label_2 = val.label_2
                this.status = Number(val.status)
                this.redirect_type = val.redirect_type
                this.android_redirect_url = val.android_redirect_url
                this.ios_redirect_url = val.ios_redirect_url
                this.bind_params = val.bind_params
                this.redirect_title = val.redirect_title
                this.version_limit_type = val.version_limit_type
                this.version = val.version
                this.redirect_jump = Number(val.is_redirect_jump)
                this.audit_mode = Number(val.audit_mode)
                this.match_mode = Number(val.match_mode)
                this.keyword = val.keyword
                this.position = val.position
                this.item_title = val.item_title
                this.shop_name = val.shop_name
                this.item_cate = val.item_cate
            },
            showExport(val) {
                this.dialog = val
            },
            dialog(val) {
                if (!val) {
                    this.close()
                }
            }
        },
        mounted() {
            this.getArrposid()
            let common = JSON.parse(window.localStorage.getItem('common'))
            if (common) {
                this.redirect_type_list = common.redirect_type_list
                this.version_limit_list = common.compare_type_list
            } else {
                api.getCommon()
            }
        },
        methods: {
            getOssToken() {
                api.getOssToken(res => {
                    this.ossToken = res.data.sign
                })
            },
            close() {
                this.$emit('clickClose', false)
            },
            imgUrl(val) {
                this.main_img = val
            },
            getArrposid() {
                api.getPlatformPageList({
                    page: 1,
                    pagesize: 100
                }, res => {
                    let obj = [{
                        id: "-1",
                        title: '搜索页',
                        name: '搜索页'
                    }, {
                        id: "-2",
                        title: '首页检测弹窗',
                        name: '首页检测弹窗'
                    }]
                    this.arrpos = [...obj, ...res.data.list]

                })
            },
            primary() {
                if (this.id != undefined && this.id != '') {
                    api.updateRecommend({
                        'id': this.id,
                        'title': this.title,
                        'subtitle': this.subtitle,
                        'main_img': this.main_img,
                        'label_1': this.label_1,
                        'label_2': this.label_2,
                        'status': this.status,
                        'redirect_type': this.redirect_type,
                        'android_redirect_url': this.android_redirect_url,
                        'ios_redirect_url': this.ios_redirect_url,
                        'bind_params': this.bind_params,
                        'redirect_title': this.redirect_title,
                        'version_limit_type': this.version_limit_type,
                        'version': this.version,
                        'is_redirect_jump': this.redirect_jump,
                        'audit_mode': this.audit_mode,
                        'match_mode': this.match_mode,
                        'keyword': this.keyword,
                        'position': this.position,
                        'item_title': this.item_title,
                        'shop_name': this.shop_name,
                        'item_cate': this.item_cate,
                    }, res => {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        })
                        this.$emit('clickClose', false)
                    })
                    return
                }
                api.postRecommend({
                    'title': this.title,
                    'subtitle': this.subtitle,
                    'main_img': this.main_img,
                    'label_1': this.label_1,
                    'label_2': this.label_2,
                    'status': this.status,
                    'redirect_type': this.redirect_type,
                    'android_redirect_url': this.android_redirect_url,
                    'ios_redirect_url': this.ios_redirect_url,
                    'bind_params': this.bind_params,
                    'redirect_title': this.redirect_title,
                    'version_limit_type': this.version_limit_type,
                    'version': this.version,
                    'is_redirect_jump': this.redirect_jump,
                    'audit_mode': this.audit_mode,
                    'match_mode': this.match_mode,
                    'keyword': this.keyword,
                    'position': this.position,
                    'item_title': this.item_title,
                    'shop_name': this.shop_name,
                    'item_cate': this.item_cate,
                }, res => {
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    })
                    this.$emit('clickClose', false)
                })
            },
        }
    }
</script>
<style scoped>
    .el-button {
        margin-top: 10px
    }
    
    .el-select {
        float: left;
    }
    
    .title {
        margin-bottom: 20px;
        text-align: left;
        font-weight: bold;
    }
</style>