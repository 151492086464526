import _import from './_import'

// 概述
export default [{
    path: '/',
    component:()=>
        import ('@/pages/public/index/index.vue'),
    children: [{
        path: 'commodity/import/index',
        name: 'commodity/importIndex',
        component: () =>
            import ('@/pages/commodity/import/index.vue'),
        meta: {
            title: '商品导入'
        }
    },{
        path: 'commodity/import/detail',
        name: 'commodity/importDetail',
        component: () =>
            import ('@/pages/commodity/import/detail.vue'),
        meta: {
            title: '导入详情'
        }
    },{
        path: 'commodity/special/index',
        name: 'commodity/specialIndex',
        component: () =>
            import ('@/pages/commodity/special/index.vue'),
        meta: {
            title: '商品专题'
        }
    },]
}]