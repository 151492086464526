import platformDialog from '@/components/platformDialog'
import recommendDialog from '@/components/recommendDialog'
import upLoad from '@/components/upLoad'
import upLoadText from '@/components/upLoadText'
import choiceDialog from '@/components/choiceDialog'
import lifeDialog from '@/components/lifeDialog'
import informationDialog from '@/components/informationDialog'
import searchDialog from '@/components/searchDialog'
import couponsDialog from '@/components/couponsDialog'
import activityDialog from '@/components/activityDialog'
export default {
    platformDialog,
    recommendDialog,
    upLoad,
    upLoadText,
    choiceDialog,
    lifeDialog,
    informationDialog,
    searchDialog,
    couponsDialog,
    activityDialog
}