import Vue from 'vue';
import Router from 'vue-router';
import routers from './routers'

Vue.use(Router)

const router = new Router({
    routes: routers
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    router.from = from
    next()
})

export default router