import _import from '@/router/_import'

export default [{
    path: '/login',
    name: 'Login',
    component: () =>
        import ('@/pages/login/index.vue'),
    meta: {
        title: '小熊管理后台'
    }
}, {
    path: '/modifyPwd',
    component: () =>
        import ('@/pages/public/index/index.vue'),
    children: [{
        path: '/modifyPwd',
        name: 'ModifyPwd',
        component: () =>
            import ('@/pages/login/modifyPwd.vue'),
        meta: {
            title: '修改密码'
        }
    }]

}]