import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import router from '@/router'
import loginDialog from '@/components/loginDialog'


export function login() {
    const LoginBox = Vue.extend(loginDialog)
    let instance = new LoginBox({}).$mount();
    if (!window.login) {
        window.login = true
        document.body.appendChild(instance.$el)
        Vue.nextTick(() => {
            instance.show = true
        })
    }

}



let CONFIG = {
    env: process.env.NODE_ENV
}

// 根据环境设置路由
if (CONFIG.env == 'test') {
    axios.defaults.baseURL = '/api';
} else if (CONFIG.env == 'development') {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
} else if (CONFIG.env == 'production') {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
} else
if (CONFIG.env == 'sandbox') {
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
}
axios.defaults.timeout = 10000
axios.defaults.withCredentials = false;



// 请求拦截
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断vuex中是否存在token        
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
        const token = store.state.userToken;
        return config;
    },
    error => {
        return Promise.error(error);
    }
)

let code = [2003, 2007, 2008]
let open = 0
    // 响应拦截
axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据    
        // 否则的话抛出错误
        if (store.state.userId == '' && router.currentRoute.path != '/login') {
            router.replace({
                path: '/login',
            });
            return
        }
        if (response.status === 200) {
            if (code.indexOf(response.data.code) != -1) {
                login()
                return false
            }
            if (response.data.code === 2010) {
                return Promise.reject(response.data);
            }
            if (response.data.code === 2001) {
                return Promise.reject(response.data);
            }
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response.data);
        }
    },
    // 服务器状态码不是2开头的的情况
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录              
                case 401:
                    router.replace({
                        path: '/login',
                    });
                    break;
                default:
                    return Promise.reject(error.response);
            }
            return Promise.reject(error.response);
        }
    }
)

export function getHeader(Content) {
    let headers = {
        'Content-Type': Content ? 'multipart/form-data' : 'application/json',
        "admin-id": store.state.userId,
        "admin-token": store.state.userToken
    }
    return headers
}


/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
                params: params,
                headers: getHeader()
            })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err)
            })
    })
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params, content) {
    console.log(params)
    return new Promise((resolve, reject) => {
        if (content) {
            axios.post(url, params, {
                    headers: getHeader(content)
                })
                .then(res => {
                    resolve(res);
                })
                .catch(err => {
                    reject(err)
                })
            return
        }
        axios.post(url, params, {
                headers: getHeader()
            })
            .then(res => {
                resolve(res);
            })
            .catch(err => {
                reject(err)
            })
    });
}

export default {
    get,
    post
}