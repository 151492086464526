import http from './http';
import { apiError } from './util';
// 登录
export function getLogin(params, callback) {
  http
    .post('login.login', params)
    .then(res => {
      if (res.code != 2019 && res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 退出
export function logout(callback) {
  http
    .post('login.logout')
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取用户能访问菜单
export function getUserMenu(callback) {
  http
    .get('auth.getMenu')
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 添加账号
export function addUser(params, callback) {
  http
    .post('adminUser.add', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改密码
export function modifyPwd(params, callback) {
  http
    .post('adminUser.modifyPwd', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取公共配置参数
export function getCommon(callback) {
  http
    .get('common.commonList')
    .then(res => {
      res.data.channel_type_list = [
        {
          type: '12',
          name: '大促会场',
        },
        {
          type: '7',
          name: '流动banner',
        },
      ];
      res.flow_ad_location_type = [
        {
          type: '-1',
          name: '精选',
        },
        {
          type: '1',
          name: '淘宝',
        },
        {
          type: '3',
          name: '拼多多',
        },
        {
          type: '2',
          name: '京东',
        },
        {
          type: '4',
          name: '唯品会',
        },
        {
          type: '9',
          name: '抖音',
        },
        {
          type: '12',
          name: '快手',
        },
      ]
      res.data.search_type_list = [
        {
          type: 5,
          name: '商品频道',
        },
        {
          type: 10,
          name: '好价频道',
        },
        {
          type: 7,
          name: '海报',
        },
        {
          type: 13,
          name: '优惠券',
        },
        {
          type: 2,
          name: '本地生活',
        },
      ];
      window.commonList = res.data;
      console.log(JSON.stringify(res.data))
      window.localStorage.setItem('common', JSON.stringify(res.data));
      callback();
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取上传参数
export function getOssToken(callback) {
  http
    .get('common.getOssToken')
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取平台页列表
export function getPlatformPageList(params, callback) {
  http
    .get('platformPage.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加平台页配置
export function postPlatformPage(params, callback) {
  http
    .post('platformPage.addItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除平台页配置
export function delPlatformPage(params, callback) {
  http
    .post('platformPage.delItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取平台页配置
export function getPlatformPage(params, callback) {
  http
    .get('platformPage.getItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改平台页配置
export function updatePlatformPage(params, callback) {
  http
    .post('platformPage.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取推荐列表
export function getRecommendList(params, callback) {
  http
    .get('recommend.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取推荐配置
export function getRecommend(params, callback) {
  http
    .get('recommend.getItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
//更新推荐状态
export function updateRecommendStatus(params, callback) {
  http
    .post('recommend.updateItemStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
//更新推荐信息
export function updateRecommend(params, callback) {
  http
    .post('recommend.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加推荐
export function postRecommend(params, callback) {
  http
    .post('recommend.addItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除平台页配置
export function delRecommend(params, callback) {
  http
    .post('recommend.delItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 唯品会订单列表
export function getVipOrderList(params, callback) {
  http
    .get('vipOrder.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 图片上传
export function upLoadImg(params, content, callback) {
  http
    .post('common.uploadImage', params, content)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 订单明细列表
export function getOrderList(params, callback) {
  http
    .get('order.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改淘宝订单
export function alterOrder(params, callback) {
  http
    .post('order.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改京东订单
export function alterJDOrder(params, callback) {
  http
    .post('jdOrder.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改拼多多订单
export function alterPddOrder(params, callback) {
  http
    .post('pddOrder.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改唯品会订单
export function alterVipOrder(params, callback) {
  http
    .post('vipOrder.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改苏宁订单
export function alterSuningOrder(params, callback) {
  http
    .post('suningOrder.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 结算订单
export function settlementOrder(params, callback) {
  http
    .post('order.settlement', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 收入明细
export function getIncome(params, callback) {
  http
    .get('income.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 提现明细
export function getWithdraw(params, callback) {
  http
    .get('withdraw.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 审核提现
export function auditWithdraw(params, callback) {
  http
    .post('withdraw.pass', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 商品明细
export function getGoodsDetailList(params, callback) {
  http
    .get('item.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
//拼多多订单
export function getPddOrderList(params, callback) {
  http
    .get('pddOrder.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 违规订单列表
export function getIllegalOrder(params, callback) {
  http
    .get('violation.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 授权列表
export function getAuthorization(params, callback) {
  http
    .get('relationDetail.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 京东订单
export function getJdOrderList(params, callback) {
  http
    .get('jdOrder.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 会员列表
export function getMember(params, callback) {
  http
    .get('appUser.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改会员资料
export function updateMember(params, callback) {
  http
    .post('appUser.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 极光rid列表
export function getJpushList(params, callback) {
  http
    .get('jpush.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 美团会员列表
export function getMtMemberList(params, callback) {
  http
    .get('meituanMember.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 美团会员重新充值
export function rechargeMtmember(params, callback) {
  http
    .post('meituanMember.recharge', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 黑名单
export function getBlackList(params, callback) {
  http
    .get('withdraw.getBlackList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除黑名单
export function delBlack(params, callback) {
  http
    .post('withdraw.delBlack', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加黑名单
export function addBlack(params, callback) {
  http
    .post('withdraw.addBlack', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 专属免单列表
export function getFreeOrder(params, callback) {
  http
    .get('itemFree.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取单个免单
export function getItemFree(params, callback) {
  http
    .get('itemFree.getAdminItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加专属免单
export function addFree(params, callback) {
  http
    .post('itemFree.addItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加专属免单
export function alterFree(params, callback) {
  http
    .post('itemFree.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取淘宝商品信息
export function getTbGoods(params, callback) {
  http
    .get('tbItem.getTbItemInfo', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 分配淘宝授权码
export function accreditTb(params, callback) {
  http
    .post('appUser.allocation', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

//取消支付宝授权
export function unaccreditZFB(params, callback) {
  http
    .post('appUser.clearZfb', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 总活动管理
export function getActivityList(params, callback) {
  http
    .get('act.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
export function postActivity(params, callback) {
  http
    .post('act.updateAct', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 夺宝活动管理
export function getSnatchTreasureMaintenance(params, callback) {
  http
    .get('actLoot.actListByItemId', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 夺宝活动商品管理
export function getSnatchTreasureGoods(params, callback) {
  http
    .get('actLoot.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 终止夺宝活动
export function postStopAct(params, callback) {
  http
    .post('actLoot.actStop', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 虚拟用户列表
export function getFakerUserList(params, callback) {
  http
    .get('actLoot.getFakerUserList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取单个夺宝商品信息
export function getActItem(params, callback) {
  http
    .get('actLoot.getItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改夺宝商品
export function updateActItem(params, callback) {
  http
    .post('actLoot.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加夺宝商品
export function addActItem(params, callback) {
  http
    .post('actLoot.addItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改夺宝商品权重
export function itemSort(params, callback) {
  http
    .post('actLoot.itemSort', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 活动自动循环
export function circulationAct(params, callback) {
  http
    .post('actLoot.updateById', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 风险用户列表
export function getActUser(params, callback) {
  http
    .get('actLootUser.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 夺宝活动解除风险用户
export function postActUser(params, callback) {
  http
    .post('actLootUser.updateUser', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 夺宝总数据
export function actInfo(params, callback) {
  http
    .get('actLootStatis.getInfo', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 夺宝活动图表数据
export function actStatis(params, callback) {
  http
    .get('actLootStatis.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 商品配置列表
export function getCommdityRecommend(params, callback) {
  http
    .get('itemStencil.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 商品配置状态
export function updateCommdityRecommendStatus(params, callback) {
  http
    .post('itemStencil.updateStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取单个商品配置
export function getOneCommdityRecommend(params, callback) {
  http
    .get('itemStencil.getInfo', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改商品配置
export function updateCommdityRecommend(params, callback) {
  http
    .post('itemStencil.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 增加商品配置
export function addCommdityRecommend(params, callback) {
  http
    .post('itemStencil.addItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 账户列表
export function getUserList(params, callback) {
  http
    .get('adminUser.list', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 更改账户状态
export function postUserChangeStatus(params, callback) {
  http
    .post('adminUser.changeStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取社群活动列表
export function getAssociationList(params, callback) {
  http
    .get('actCommunity.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加奖池
export function addAssociation(params, callback) {
  http
    .post('actCommunity.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加社群商品
export function addAssociationItem(params, callback) {
  http
    .post('actCommunity.addItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取社群用户列表
export function getAssociationUser(params, callback) {
  http
    .get('actCommunity.getUserList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 封禁、解封社群用户
export function updataAssociationUser(params, callback) {
  http
    .post('actCommunity.updateUserStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取社群活动详情
export function getAssociationDetail(params, callback) {
  http
    .get('actCommunity.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取社群活动详情商品列表
export function getAssociationDetailShop(params, callback) {
  http
    .get('actCommunity.getItemList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 社群详情删除商品
export function delAssociationShop(params, callback) {
  http
    .post('actCommunity.delItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 重置密码
export function postResetPwd(params, callback) {
  http
    .post('adminUser.resetPwd', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取角色列表
export function getRole(params, callback) {
  http
    .get('auth.roleList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加角色
export function addRoleUser(params, callback) {
  http
    .post('auth.addRole', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改角色
export function updataRoleUser(params, callback) {
  http
    .post('auth.updateRole', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 分配角色
export function getUserRole(params, callback) {
  http
    .post('auth.assignRole', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 分配菜单
export function getMenu(params, callback) {
  http
    .post('auth.assignMenu', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 分配权限
export function getAuth(params, callback) {
  http
    .post('auth.assignAuth', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取所有菜单
export function getAllMenu(params, callback) {
  http
    .get('auth.getAllMenu', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取所有API
export function getAllApi(params, callback) {
  http
    .get('auth.getAllApi', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取某个角色的菜单
export function getRoleAllMenu(params, callback) {
  http
    .get('auth.getRoleAllMenu', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取某个角色的权限
export function getRoleAllAuth(params, callback) {
  http
    .get('auth.getRoleAllAuth', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取菜单列表
export function getMenulist(params, callback) {
  http
    .get('auth.menuList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改菜单状态
export function changeMenuStatus(params, callback) {
  http
    .post('auth.changeMenuStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改菜单
export function updateMenu(params, callback) {
  http
    .post('auth.updateMenu', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加菜单
export function addMenu(params, callback) {
  http
    .post('auth.addMenu', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取api列表
export function getApilist(params, callback) {
  http
    .get('auth.apiList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改菜单状态
export function changeApiStatus(params, callback) {
  http
    .post('auth.changeApiStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改菜单
export function updateApi(params, callback) {
  http
    .post('auth.updateApi', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加菜单
export function addApi(params, callback) {
  http
    .post('auth.addApi', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取推广渠道列表
export function getChannelList(params, callback) {
  http
    .get('channel.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除推广渠道
export function delChannel(params, callback) {
  http
    .post('channel.deleteChannel', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取单个推广渠道
export function getChannel(params, callback) {
  http
    .get('channel.getInfo', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改推广
export function updateChannel(params, callback) {
  http
    .post('channel.updateChannel', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加推广
export function addChannel(params, callback) {
  http
    .post('channel.addChannel', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取人工选品列表
export function getChoiceGoodsList(params, callback) {
  http
    .get('itemSelect.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 人工选品上下架
export function updateChoiceGoodsStatus(params, callback) {
  http
    .post('itemSelect.updateItemStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取选品详情
export function getChoiceGoodsDetail(params, callback) {
  http
    .get('itemSelect.getInfo', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加选品
export function addChoiceGoods(params, callback) {
  http
    .post('itemSelect.addItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改选品
export function updateChoiceGoods(params, callback) {
  http
    .post('itemSelect.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 选品获取商品信息
export function getGoodsInfo(params, callback) {
  http
    .get('item.getItemInfo', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价商品列表
export function getGoodsList(params, callback) {
  http
    .get('itemSelling.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价商品上下架
export function sellingUpdateStatus(params, callback) {
  http
    .post('itemSelling.updateStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价商品修改
export function updateSellingDateil(params, callback) {
  http
    .post('itemSelling.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价商品添加
export function addSellingDateil(params, callback) {
  http
    .post('itemSelling.addItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价商品转链
export function turnChain(params, callback) {
  http
    .post('item.turnChain', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价商品详情
export function getGoodsItem(params, callback) {
  http
    .get('itemSelling.getItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价优惠举报列表
export function getSellingReportList(params, callback) {
  http
    .get('itemSellingReport.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价优惠举报详情
export function getSellingReportDate(params, callback) {
  http
    .get('itemSellingReport.getReportDetail', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价标记优惠券失效
export function updateCouponStatus(params, callback) {
  http
    .post('itemSellingReport.updateCouponStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价处理举报信息
export function updateReportInfo(params, callback) {
  http
    .post('itemSellingReport.updateReportInfo', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价分类列表
export function getSellingClassifyList(params, callback) {
  http
    .get('itemCategory.getCate', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价分类修改
export function updateCate(params, callback) {
  http
    .post('itemCategory.updateCate', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价分类删除
export function delCate(params, callback) {
  http
    .post('itemCategory.delCate', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 好价分类添加
export function addCate(params, callback) {
  http
    .post('itemCategory.batchAddCate', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 分类排序
export function sortCate(params, callback) {
  http
    .post('itemCategory.sort', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 频道分类列表
export function getChannelClassifyList(params, callback) {
  http
    .get('lifeChannel.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 频道分类详情
export function getChannelClassifyDetail(params, callback) {
  http
    .get('lifeChannel.getInfo', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加频道分类
export function addChannelClassify(params, callback) {
  http
    .post('lifeChannel.addChannel', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改频道分类
export function updateChannelClassify(params, callback) {
  http
    .post('lifeChannel.updateChannel', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除频道分类
export function delChannelClassify(params, callback) {
  http
    .post('lifeChannel.deleteChannel', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取生活频道列表
export function getLifeChannel(params, callback) {
  http
    .get('lifeChannel.getNavList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取生活频道详情
export function getLifeChannelDetail(params, callback) {
  http
    .get('lifeChannel.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 更新生活频道
export function updateLifeChannelDetail(params, callback) {
  http
    .post('lifeChannel.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加生活频道
export function addLifeChannelDetail(params, callback) {
  http
    .post('lifeChannel.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 社群淘礼金列表
export function getActCommunityList(params, callback) {
  http
    .get('actCommunityGift.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 社群淘礼金单个详情
export function getActCommunityData(params, callback) {
  http
    .get('actCommunityGift.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加社群淘礼金
export function addActCommunity(params, callback) {
  http
    .post('actCommunityGift.addItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改社群淘礼金
export function updateActCommunity(params, callback) {
  http
    .post('actCommunityGift.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 上下架社群淘礼金
export function updateActCommunityStatus(params, callback) {
  http
    .post('actCommunityGift.updateStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 美团订单
export function getMtOrderList(params, callback) {
  http
    .get('meituanOrder.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 苏宁订单
export function getSnOrderList(params, callback) {
  http
    .get('suningOrder.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 商品库列表
export function getGoodLibList(params, callback) {
  http
    .get('itemLib.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 商品库详情
export function getGoodLibDetail(params, callback) {
  http
    .get('itemLib.itemDetail', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 手动添加商品
export function addGoodLi(params, callback) {
  http
    .post('itemLib.addItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 手动编辑商品
export function updateGoodLib(params, callback) {
  http
    .post('itemLib.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 加入专题
export function addItemTopic(params, callback) {
  http
    .post('itemLib.addItemTopic', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取专题列表
export function getTopicByKeywordList(params, callback, errEve = () => {}) {
  http
    .get('itemTopic.getTopicByKeywordList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// Excel商品导入
export function addExcelData(params, callback, errEve = () => {}) {
  http
    .post('itemTask.addExcelData', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}

// 修改Excel商品导入
export function updateExcelData(params, callback, errEve = () => {}) {
  http
    .post('itemTask.updateExcelData', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 商品导入列表
export function addTbMaterial(params, callback, errEve = () => {}) {
  http
    .post('itemTask.addTbMaterial', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 修改商品导入列表
export function updateTbMaterial(params, callback, errEve = () => {}) {
  http
    .post('itemTask.updateTbMaterial', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 商品导入详情
export function getComImportDetail(params, callback, errEve = () => {}) {
  http
    .get('itemLib.getTaskDetail', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 商品导入任务详情
export function getComItemTaskDetail(params, callback, errEve = () => {}) {
  http
    .get('itemTask.getItemTaskDetail', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 商品导入列表
export function getCommodityList(params, callback, errEve = () => {}) {
  http
    .get('itemTask.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 商品导入详情
export function getCommodityDetail(params, callback, errEve = () => {}) {
  http
    .get('itemTask.getTaskDetail', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 商品导入 更新状态
export function updateTaskStatus(params, callback, errEve = () => {}) {
  http
    .post('itemTask.updateTaskStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}

// 商品专题列表
export function getCommoditySpecialList(params, callback, errEve = () => {}) {
  http
    .get('itemTopic.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}

// 添加商品专题
export function addCommoditySpecialItem(params, callback, errEve = () => {}) {
  http
    .post('itemTopic.addItemTopic', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}

// 商品专题修改
export function updateCommoditySpecialItem(
  params,
  callback,
  errEve = () => {},
) {
  http
    .post('itemTopic.updateItemTopic', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}

// 移除专题
export function delItemTopic(params, callback) {
  http
    .post('itemLib.deleteItemTopic', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}

// 修改商品专题状态
export function updateCommoditySpecialItemStatus(
  params,
  callback,
  errEve = () => {},
) {
  http
    .post('itemTopic.updateTopicStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
//标记优惠券失效
export function goodsLibUpdateCouponStatus(params, callback) {
  http
    .post('itemLib.updateCouponStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}

// 获取商品专题信息
export function getCommoditySpecialItemInfo(
  params,
  callback,
  errEve = () => {},
) {
  http
    .get('itemTopic.getItemTopicInfo', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
//标记活动失效
export function goodsLibupdateActivityStatus(params, callback) {
  http
    .post('itemLib.updateActivityStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}

// 文件上传
export function upLoadFile(params, content, callback, errEve = () => {}) {
  http
    .post('common.uploadFile', params, content)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 中转页列表
export function getTransferList(params, callback) {
  http
    .get('relayPage.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 中转页详情
export function getTransferData(params, callback) {
  http
    .get('relayPage.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加中转页
export function addTransfer(params, callback) {
  http
    .post('relayPage.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改中转页
export function updateTransfer(params, callback) {
  http
    .post('relayPage.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除中转页
export function delTransfer(params, callback) {
  http
    .post('relayPage.delRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取钻石位列表
export function getDiamondList(params, callback) {
  http
    .get('appNav.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取钻石位详情
export function getDiamondData(params, callback) {
  http
    .get('appNav.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加钻石位
export function addDiamond(params, callback) {
  http
    .post('appNav.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改钻石位
export function updateDiamond(params, callback) {
  http
    .post('appNav.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 活动宣传口列表
export function getAdvertiseList(params, callback) {
  http
    .get('activityConfig.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 活动宣传口详情
export function getAdvertiseData(params, callback) {
  http
    .get('activityConfig.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      // errEve(err)
      console.log(err);
      apiError(err);
    });
}
// 搜索专题列表
export function queryItemTopicOptions(params, callback) {
  http
    .get('itemTopic.getTopicByKeywordList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加活动宣传口
export function addAdvertise(params, callback) {
  http
    .post('activityConfig.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改活动宣传口
export function updateAdvertise(params, callback) {
  http
    .post('activityConfig.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除活动宣传口
export function delAdvertise(params, callback) {
  http
    .post('activityConfig.delRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 单个配置项列表
export function getConfigList(params, callback) {
  http
    .get('systemConfig.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 单个配置项详情
export function getConfigData(params, callback) {
  http
    .get('systemConfig.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加单个配置项
export function addConfig(params, callback) {
  http
    .post('systemConfig.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改单个配置项
export function updateConfig(params, callback) {
  http
    .post('systemConfig.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 唯品会订单导出
export function exportVipOrder(params, callback) {
  http
    .get('vipOrder.importOrderList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 免单兑换记录
export function getRecordList(params, callback) {
  http
    .get('', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 获取任务列表
export function getActivityTaskList(params, callback, errEve = () => {}) {
  http
    .get('activityTask.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 获取单条任务信息
export function getActivityTaskRow(params, callback, errEve = () => {}) {
  http
    .get('activityTask.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 开启关闭任务
export function updateActivityTaskStatus(params, callback, errEve = () => {}) {
  http
    .post('activityTask.updateStatus', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 添加任务
export function addActivityTaskRow(params, callback, errEve = () => {}) {
  http
    .post('activityTask.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 更新任务
export function updateActivityTaskRow(params, callback, errEve = () => {}) {
  http
    .post('activityTask.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 免单商品列表
export function getItemFreeList(params, callback, errEve = () => {}) {
  http
    .get('itemFree.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 免单商品记录
export function getItemFreeRecords(params, callback, errEve = () => {}) {
  http
    .get('itemFree.getRecords', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 获取单个免单商品
export function getItemFreeAdminItem(params, callback, errEve = () => {}) {
  http
    .get('itemFree.getAdminItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 修改免单商品
export function updateItemFreeItem(params, callback, errEve = () => {}) {
  http
    .post('itemFree.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 添加免单商品
export function addItemFreeItem(params, callback, errEve = () => {}) {
  http
    .post('itemFree.addItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      errEve(err);
      apiError(err);
    });
}
// 选品订单
export function getSelectOrder(params, callback) {
  http
    .get('selectOrder.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取pid列表
export function getStatisPid(params, callback) {
  http
    .get('statisPid.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
export function addStatisPid(params, callback) {
  http
    .post('statisPid.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除pid
export function delStatisPid(params, callback) {
  http
    .post('statisPid.delRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 店铺黑名单
export function shopBlackList(params, callback) {
  http
    .get('shopBlackList.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加店铺黑名单
export function addshopBlackList(params, callback) {
  http
    .post('shopBlackList.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除店铺黑名单
export function delshopBlackList(params, callback) {
  http
    .post('shopBlackList.deleteRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
//用户充值列表
export function userRechargeList(params, callback) {
  http
    .get('userRecharge.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 充值
export function adduserRecharge(params, callback, errEve = () => {}) {
  http
    .post('userRecharge.userRecharge', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
        errEve(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
      errEve(err);
    });
}
// 推广位佣金配置列表
export function commissionConfigList(params, callback) {
  http
    .get('commissionConfig.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加推广位佣金配置
export function addcommissionConfig(params, callback) {
  http
    .post('commissionConfig.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改推广位佣金配置
export function updatecommissionConfig(params, callback) {
  http
    .post('commissionConfig.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取推广位佣金配置详情
export function getcommissionConfig(params, callback) {
  http
    .get('commissionConfig.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
//获取公众号回复列表
export function getWechatList(params, callback) {
  http
    .get('wechat.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
//获取公众号回复详情
export function getWechat(params, callback) {
  http
    .get('wechat.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
//添加公众号回复详情
export function addWechat(params, callback) {
  http
    .post('wechat.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
//修改公众号回复详情
export function updateWechat(params, callback) {
  http
    .post('wechat.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取媒体id
export function weChatMediaId(params, callback) {
  http
    .post('wechat.weChatMediaId', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 其他功能列表
export function getRestList(params, callback) {
  http
    .get('other.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 其他功能详情
export function getRest(params, callback) {
  http
    .get('other.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加其他功能
export function addRest(params, callback) {
  http
    .post('other.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 更新其他功能
export function updateRest(params, callback) {
  http
    .post('other.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 信息流管理列表
export function getInformationList(params, callback) {
  http
    .get('configChannel.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取信息流管理
export function getInformation(params, callback) {
  http
    .get('configChannel.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加信息流管理
export function addInformation(params, callback) {
  http
    .post('configChannel.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改信息流管理
export function updateInformation(params, callback) {
  http
    .post('configChannel.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改信息流管理状态权限
export function updateInformationDate(params, callback) {
  http
    .post('configChannel.update', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除信息流管理
export function delInformation(params, callback) {
  http
    .post('configChannel.deleteRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 获取搜索管理了列表
export function getSearchList(params, callback) {
  http
    .get('searchManage.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 搜索管理详情
export function getSearch(params, callback) {
  http
    .get('searchManage.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加搜索管理
export function addSearch(params, callback) {
  http
    .post('searchManage.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 更新补贴订单状态
export function updateSubsidies(params, callback) {
  http
    .post('actSubsidizeOrder.orderAudit', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改搜索管理
export function updateSearch(params, callback) {
  http
    .post('searchManage.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 修改搜索管理状态权限
export function updateSearchDate(params, callback) {
  http
    .post('searchManage.updateField', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除信息流管理
export function delSearch(params, callback) {
  http
    .post('searchManage.delRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 获取优惠券列表
export function getCouponList(params, callback) {
  http
    .get('couponManage.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取优惠券列表
export function getCoupon(params, callback) {
  http
    .get('couponManage.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加优惠券
export function addCoupon(params, callback) {
  http
    .post('couponManage.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改优惠券
export function updateCoupon(params, callback) {
  http
    .post('couponManage.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除优惠券
export function delCoupon(params, callback) {
  http
    .post('couponManage.delRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 活动配置列表
export function getActivityCofigList(params, callback) {
  http
    .get('appActivityConfig.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 活动配置列表详情
export function getActivityCofig(params, callback) {
  http
    .get('appActivityConfig.getRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加活动配置列表
export function addActivityCofig(params, callback) {
  http
    .post('appActivityConfig.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改活动配置列表
export function updateActivityCofig(params, callback) {
  http
    .post('appActivityConfig.updateRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改活动配置列表权重
export function updateActivityCofigWeight(params, callback) {
  http
    .post('appActivityConfig.updateField', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除活动配置列表
export function delActivityCofig(params, callback) {
  http
    .post('appActivityConfig.delRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取分类
export function getClass(params, callback) {
  http
    .get('itemCategory.cascadeQuery', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 检测是否已经存在优惠券
export function checkSearchCouon(callback, error) {
  http
    .get('searchManage.checkCoupon')
    .then(res => {
      if (res.code != 2000) {
        // apiError(res)
      } else {
        callback(res);
      }
    })
    .catch(err => {
      // apiError(err)
      error(err);
    });
}

// 删除活动配置列表
export function updateActivityWeight(params, callback) {
  http
    .post('activityConfig.updateWeight', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 获取补贴订单列表
export function getSubsidiesList(params, callback) {
  http
    .get('actSubsidizeOrder.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 类目数据回填
export function getItemCate(params, callback) {
  http
    .get('itemCategory.getCateInfo', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 双十一社群活动列表
export function getDoubleElevenActList(params, callback) {
  http
    .get('actCommunityPreOder.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加双十一社群活动
export function addDoubleElevenActList(params, callback) {
  http
    .post('actCommunityPreOder.addRow', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 双十一社群活动详情
export function getDoubleElevenActData(params, callback) {
  http
    .get('actCommunityPreOder.getItemList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 添加双十一社群活动
export function addDoubleElevenActShop(params, callback) {
  http
    .post('actCommunityPreOder.addItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 删除双十一社群活动
export function delDoubleElevenActShop(params, callback) {
  http
    .post('actCommunityPreOder.delItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 双十一预售用户列表
export function getDoubleElevenActUserLisst(params, callback) {
  http
    .get('actCommunityPreOder.bindOrderList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 商品库专题导入
export function improtGoodTopic(params, callback) {
  http
    .post('itemTask.addImportTopic', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 更新商品库专题导入
export function updateImprotGoodTopic(params, callback) {
  http
    .post('itemTask.updateImportTopic', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 商品导入详情
export function getGoodsImportTaskDetail(params, callback) {
  http
    .get('itemTask.detail', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 结算pdd
export function pddOrderSettlement(params, callback) {
  http
    .post('pddOrder.settlement', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 结算JD
export function jdOrderSettlement(params, callback) {
  http
    .post('jdOrder.settlement', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 结算vip
export function vipOrderSettlement(params, callback) {
  http
    .post('vipOrder.settlement', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 结算SN
export function suningOrdeSsettlement(params, callback) {
  http
    .post('suningOrder.settlement', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 结算mt
export function meituanOrderSettlement(params, callback) {
  http
    .post('meituanOrder.settlement', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 修改mt
export function meituanOrderUpdateItem(params, callback) {
  http
    .post('meituanOrder.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 结算dd
export function ddOrderSettlement(params, callback) {
  http
    .post('ddOrder.settlement', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改dd
export function ddOrderUpdateItem(params, callback) {
  http
    .post('ddOrder.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 获取dd
export function ddOrderGetList(params, callback) {
  http
    .get('ddOrder.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 获取elm
export function eleOrderGetList(params, callback) {
  http
    .get('eleOrder.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 修改elm
export function eleOrderUpdateItem(params, callback) {
  http
    .post('eleOrder.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 结算elm
export function eleOrderSettlement(params, callback) {
  http
    .post('eleOrder.settlement', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 上传违规订单-导入
export function violationAddExcelData(params, callback, error) {
  http
    .post('violation.addExcelData', params)
    .then(res => {
      if (res.code != 2000) {
        error && error();
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      error && error();
      apiError(err);
    });
}

// 快手订单
export function ksOrderList(params, callback) {
  http
    .get('ksOrder.getList', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}

// 结算快手
export function ksOrderSettlement(params, callback) {
  http
    .post('ksOrder.settlement', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
// 修改快手
export function ksOrderUpdateItem(params, callback) {
  http
    .post('ksOrder.updateItem', params)
    .then(res => {
      if (res.code != 2000) {
        apiError(res);
      } else {
        callback(res);
      }
    })
    .catch(err => {
      apiError(err);
    });
}
