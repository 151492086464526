import { Message } from 'element-ui';

// 接口错误统一处理
export function apiError(err) {
    switch (err.code) {
        case 2001:
            Message({ type: 'error', message: err.msg })
            break;
        case 2010:
            Message({ type: 'error', message: err.msg })
            break;
        case 2003:
            Message({ type: 'error', message: 'token错误' })
            break;
        case 2004:
            Message({ type: 'error', message: '数据不存在' })
            break;
        case 2005:
            Message({ type: 'error', message: err.msg != '' ? err.msg : '系统错误' })
            break;
        case 2006:
            Message({ type: 'error', message: err.msg != '' ? err.msg : '接口不存在' })
            break;
        case 2011:
            Message({ type: 'error', message: err.msg != '' ? err.msg : '需要淘宝授权' })
            break;
        case 2012:
            Message({ type: 'error', message: err.msg != '' ? err.msg : '需要拼多多授权' })
            break;
        case 2050:
            Message({ type: 'error', message: err.msg != '' ? err.msg : '未知错误' })
            break;
        case 2018:
            Message({ type: 'error', message: err.msg })
            break;
        default:
            // Message({ type: 'error', message: err.msg })
            break;
    }
}
export function setStorage(name, data) {
    window.localStorage.setItem(name, data)
}
export function getStorage(name) {
    let storage = JSON.parse(window.localStorage.getItem(name))
    return storage
}