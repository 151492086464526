import _import from './_import'
// 概述
export default [{
    path: '/',
    component: () =>
        import ('@/pages/public/index/index.vue'),
    children: [{
        path: 'home/entry',
        component: () =>
            import ('@/pages/home/index.vue'),
        meta: {
            title: '首页'
        },
    }]
}]