<template>
<div v-show="showExport">
    <el-dialog title="添加/修改平台" width="80%" :visible.sync="dialog" :close-on-click-modal=false>
        <el-row>
            <el-form label-width="120px">
                <el-form-item label="活动名称">
                    <el-input v-model="name" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="活动特点">
                    <el-input v-model="trait" placeholder=""></el-input>
                </el-form-item>
                <el-form-item label="活动时间">
                    <el-date-picker v-model="activity_start_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                    -
                    <el-date-picker v-model="activity_end_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
                 <el-form-item label="活动标签">
                     <upLoad id="up" v-model="tag"></upLoad>
                </el-form-item>

                <el-form-item label="适用平台">
                    <el-radio-group v-model="platform_type">
                        <el-radio label="1">淘宝</el-radio>
                        <el-radio label="3">拼多多</el-radio>
                        <el-radio label="2">京东</el-radio>
                        <el-radio label="4">唯品会</el-radio>
                        <el-radio label="7">苏宁</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="适合类目">
                    <el-cascader ref="cascader" :props="props" v-model="cate"></el-cascader>
                </el-form-item>
                <el-form-item label="跳转类型">
                    <el-select v-model="redirect_type" placeholder="请选择">
                        <el-option
                        v-for="item in redirect_type_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="安卓领券链接">
                    <el-input v-model="android_redirect_url" placeholder="请输入安卓跳转链接"></el-input>
                </el-form-item>
                <el-form-item label="ios领券链接">
                    <el-input v-model="ios_redirect_url" placeholder="请输入ios跳转链接"></el-input>
                </el-form-item>
                <el-form-item label="携带参数">
                    <el-input v-model="bind_params" placeholder="请输入ios跳转链接"></el-input>
                </el-form-item>
                <el-form-item label="跳转标题">
                    <el-input v-model="redirect_title" placeholder="请输入ios跳转链接"></el-input>
                </el-form-item>
                <el-form-item label="时间范围">
                    <el-date-picker v-model="start_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                    -
                    <el-date-picker v-model="end_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期时间">
                    </el-date-picker>
                    <el-button type="primary" @click="add_td_tiem">特定时间</el-button>
                </el-form-item>
                <el-form-item label="" v-for="(item,index) in specific_time" :key="index">
                    <el-time-picker is-range v-model="specific_time[index]" value-format="HH:mm" format="HH:mm" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围">
                    </el-time-picker>
                    <el-button type="danger" @click="del_specific_time_item(index)">删除</el-button>
                </el-form-item>
                <el-form-item label="是否显示">
                    <el-radio-group v-model="status">
                        <el-radio label="1">显示</el-radio>
                        <el-radio label="2">隐藏</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="显示类型">
                    <el-radio-group v-model="show_type">
                        <el-radio v-for="item in showList" :key="item.type" :label="item.type">{{ item.name }}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="版本号限制">
                    <el-radio-group v-model="version_type">
                        <el-radio v-for="item in version_limit_list" :label="item.type">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="版本号">
                    <el-input v-model="version" placeholder="请输入版本号"></el-input>
                </el-form-item>
                <el-form-item label="审核是否显示">
                    <el-radio-group v-model="is_show">
                        <el-radio label="1">显示</el-radio>
                        <el-radio label="2">隐藏</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="用户分群">
                    <el-select v-model="group_id" multiple placeholder="">
                        <el-option v-for="item in groupList" :key="item.type" :label="item.name" :value="item.type"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="行为">
                    <el-select v-model="behavior_type" placeholder="">
                        <el-option v-for="item in behaviorList" :key="item.type" :label="item.name" :value="item.type"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="最近x天">
                    <el-input v-model="day_number" placeholder=""></el-input>
                </el-form-item>
            </el-form>
        </el-row>
        <div slot="footer" class="dialog-footer">
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="primary">确 定</el-button>
        
        </div>
    </el-dialog>
</div>

</template>
<script>
    let common = JSON.parse(window.localStorage.getItem('common'))
    import * as api from '@/config/api'
    export default {
        name: 'activityDialog',
        props: {
            showExport: Boolean,
            platform: Object
        },
        data() {
            let that = this
            return {
                active: 0,

                search_type_list: common.search_type_list,
                channel: '',

                ossToken: {},


                redirect_type_list: [],
                version_limit_list: [],
                groupList: common.user_group_list,
                behaviorList: [...[{
                    type: '0',
                    name: '不限'
                }], ...common.user_behavior_list],
                showList: common.show_type_list,

                dialog: false,
                id: '',
                name: '',
                trait: '',
                activity_start_time: '',
                activity_end_time: '',
                tag: '',
                platform_type: '',

                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
                bind_params: '',
                redirect_title: '',
                version_type: '',
                version: '',
                start_time: "",
                end_time: "",
                specific_time: [],
                status: "",
                group_id: "",
                is_show: "",
                day_number: "",
                behavior_type: "",
                show_type: '',

                props: {
                    lazy: true,
                    lazyLoad(node, resolve) {
                        setTimeout(() => {
                            if (that.platform_type == undefined) {
                                return
                            }
                            api.getClass({
                                platform: that.platform_type,
                                level: node.level + 1,
                                cate_id: node.level === 0 ? 0 : node.data.value
                            }, res => {
                                const date = res.data.map((item) => ({
                                    value: item.cate_id,
                                    label: item.cate_name,
                                }))
                                resolve(date)
                            })
                        }, 1000);
                    }
                },

                cate: [],

                first_cate_id: '',
                second_cate_id: '',
                third_cate_id: '',
                four_cate_id: '',


            }
        },
        watch: {
            platform(val) {
                console.log(val.activity_start_time)
                this.id = val.id
                this.name = val.name
                this.trait = val.trait
                this.activity_start_time = Number(val.activity_start_time).isNaN != undefined ? '' : val.activity_start_time != undefined ? this.getTime(Number(val.activity_start_time)) : '';
                this.activity_end_time = Number(val.activity_end_time).isNaN != undefined ? '' : val.activity_end_time != undefined ? this.getTime(Number(val.activity_end_time)) : '';
                this.tag = val.tag
                this.platform_type = val.platform

                this.redirect_type = val.redirect_type
                this.android_redirect_url = val.android_redirect_url
                this.ios_redirect_url = val.ios_redirect_url
                this.bind_params = val.bind_params
                this.redirect_title = val.redirect_title
                this.version_type = val.version_type
                this.version = val.version
                this.start_time = Number(val.start_time).isNaN != undefined ? '' : val.start_time != undefined ? this.getTime(Number(val.start_time)) : '';
                this.end_time = Number(val.end_time).isNaN != undefined ? '' : val.end_time != undefined ? this.getTime(Number(val.end_time)) : '';
                let specific_time = val.specific_time;
                let specific_time_arr = [];
                if (specific_time) {
                    specific_time.forEach((item, index) => {
                        specific_time_arr.push([item.start_time, item.end_time]);
                    });
                    this.specific_time = specific_time_arr;
                } else {
                    this.specific_time = []
                }
                this.status = val.status
                this.group_id = val.group_id != undefined && val.group_id != '' ? val.group_id.split(',') : ''
                this.is_show = val.is_show
                this.day_number = val.day_number
                this.behavior_type = val.behavior_type
                this.show_type = val.show_type

                this.first_cate_id = val.first_cate_id
                this.second_cate_id = val.second_cate_id
                this.third_cate_id = val.third_cate_id
                this.four_cate_id = val.four_cate_id

                this.cate = [val.first_cate_id, val.second_cate_id, val.third_cate_id, val.four_cate_id]

                if (val.platform != undefined) {
                    this.cateValue(val.first_cate_id, val.second_cate_id, val.third_cate_id, val.four_cate_id)
                } else {
                    if (this.$refs.cascader.inputValue != '') {
                        this.$refs.cascader.inputValue = ''
                    }
                }
            },
            logo(val) {
                this.img = val
            },
            showExport(val) {
                this.dialog = val
            },
            dialog(val) {
                if (!val) {
                    this.close()
                }
            },
            platform_type(val) {
                if (val == undefined) {
                    return
                }
                this.$nextTick(() => {
                    this.$refs.cascader.panel.lazyLoad()
                    this.cate = []
                })
            },
            cate(val) {
                console.log(val)
            }
        },
        mounted() {
            let common = JSON.parse(window.localStorage.getItem('common'))
            if (common) {
                this.redirect_type_list = common.redirect_type_list
                this.version_limit_list = common.compare_type_list
            } else {
                api.getCommon()
            }
        },
        methods: {
            getTime(time) {
                let Time = new Date(time * 1000);
                return `${Time.getFullYear()}-${Time.getMonth() + 1 < 10 ? "0" + (Time.getMonth() + 1) : Time.getMonth() + 1}-${Time.getDate() < 10 ? "0" + Time.getDate() : Time.getDate()} ${Time.getHours() < 10 ? "0" + Time.getHours() : Time.getHours()}:${Time.getMinutes() < 10 ? "0" + Time.getMinutes() : Time.getMinutes()}:${Time.getSeconds() < 10 ? "0" + Time.getSeconds() : Time.getSeconds()}`
            },
            close() {
                this.active = 0
                this.$emit('clickClose', false)
            },
            //   添加特定时间
            add_td_tiem() {
                this.specific_time.push(['', ''])
            },
            //   删除特定时间
            del_specific_time_item(index) {
                this.specific_time.splice(index, 1);
            },
            primary() {
                let specific_time = [];
                this.specific_time.forEach(item => {
                    specific_time.push({
                        start_time: item[0],
                        end_time: item[1],
                    })
                })
                if (this.cate.length != 0) {
                    this.first_cate_id = this.cate[0] == undefined ? '' : this.cate[0]
                    this.second_cate_id = this.cate[1] == undefined ? '' : this.cate[1]
                    this.third_cate_id = this.cate[2] == undefined ? '' : this.cate[2]
                    this.four_cate_id = this.cate[3] == undefined ? '' : this.cate[3]
                }
                if (this.id != '' && this.id != undefined) {
                    api.updateActivityCofig({
                        id: this.id,
                        name: this.name,
                        trait: this.trait,
                        activity_start_time: this.activity_start_time,
                        activity_end_time: this.activity_end_time,
                        tag: this.tag,
                        platform: this.platform_type,
                        redirect_type: this.redirect_type,
                        android_redirect_url: this.android_redirect_url,
                        ios_redirect_url: this.ios_redirect_url,
                        bind_params: this.bind_params,
                        redirect_title: this.redirect_title,
                        version_type: this.version_type,
                        version: this.version,
                        start_time: this.start_time,
                        end_time: this.end_time,
                        specific_time: specific_time,
                        status: this.status,
                        group_id: this.group_id,
                        is_show: this.is_show,
                        day_number: this.day_number,
                        behavior_type: this.behavior_type,
                        show_type: this.show_type,
                        first_cate_id: this.first_cate_id,
                        second_cate_id: this.second_cate_id,
                        third_cate_id: this.third_cate_id,
                        four_cate_id: this.four_cate_id,
                    }, res => {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        })
                        this.$emit('clickClose', false)
                    })
                    return
                }
                api.addActivityCofig({
                    name: this.name,
                    trait: this.trait,
                    activity_start_time: this.activity_start_time,
                    activity_end_time: this.activity_end_time,
                    tag: this.tag,
                    platform: this.platform_type,
                    redirect_type: this.redirect_type,
                    android_redirect_url: this.android_redirect_url,
                    ios_redirect_url: this.ios_redirect_url,
                    bind_params: this.bind_params,
                    redirect_title: this.redirect_title,
                    version_type: this.version_type,
                    version: this.version,
                    start_time: this.start_time,
                    end_time: this.end_time,
                    specific_time: specific_time,
                    status: this.status,
                    group_id: this.group_id,
                    is_show: this.is_show,
                    day_number: this.day_number,
                    behavior_type: this.behavior_type,
                    show_type: this.show_type,
                    first_cate_id: this.first_cate_id,
                    second_cate_id: this.second_cate_id,
                    third_cate_id: this.third_cate_id,
                    four_cate_id: this.four_cate_id,
                }, res => {
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    })
                    this.$emit('clickClose', false)
                })
            },
            cateValue(first_cate_id, second_cate_id, third_cate_id, four_cate_id) {
                api.getItemCate({
                    platform: this.platform_type,
                    first_cate_id,
                    second_cate_id,
                    third_cate_id,
                    four_cate_id
                }, res => {
                    // console.log(res.data)
                    let val = ''
                    for (let key in res.data) {
                        if (res.data[key].cate_name != '') {
                            val = val + res.data[key].cate_name + '/'
                        } else {
                            val = val + res.data[key].cate_name
                                // return
                        }
                    }
                    console.log(val)
                    this.$refs.cascader.inputValue = val
                })
            }
        }
    }
</script>
<style scoped>
    .el-button {
        margin-top: 10px
    }
    
    .el-select {
        float: left;
    }
    
    .export-box {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .3);
        z-index: 20;
    }
    
    .title {
        margin-bottom: 20px;
        text-align: left;
        font-weight: bold
    }
    
    .from {
        margin-top: 30px;
    }
</style>