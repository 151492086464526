<template>
<div v-show="showExport">
    <el-dialog title="添加/修改平台" :visible.sync="dialog" :close-on-click-modal=false>
        <el-row>
            <el-form label-width="120px">
                <el-form-item label="平台">
                    <el-select v-model="platform_type" placeholder="请选择">
                        <el-option
                        v-for="item in platform_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item label="商品ID">
                    <el-input v-model="item_id" placeholder="请输入商品ID" @blur="getGoodsInfo"></el-input>
                </el-form-item>
                 <el-form-item label="券后价">
                    <el-input v-model="end_price" placeholder="请输入券后价"></el-input>
                </el-form-item>
                <el-form-item label="商品标题">
                    <el-input v-model="item_title" placeholder="请输入商品标题"></el-input>
                </el-form-item>
                <el-form-item label="优惠券">
                    <el-input v-model="coupon_amount" placeholder="请输入优惠券"></el-input>
                </el-form-item>
                <el-form-item label="商品主图">
                    <upLoadText @url="imgUrl" id="up" v-model="item_pic"></upLoadText>
                </el-form-item>
                <el-form-item label="佣金比例">
                    <el-input v-model="commission_rate" placeholder="请输入佣金比例"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio-group v-model="status">
                      <el-radio :label="1">上架</el-radio>
                      <el-radio :label="2">下架</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="位置">
                    <el-select v-model="location_arr" multiple placeholder="请选择">
                        <el-option
                        v-for="item in location_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类目">
                    <el-select v-model="category_id" placeholder="请选择">
                        <el-option
                        v-for="item in classify_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="搜索关键词">
                    <el-input v-model="keyword" placeholder="请输入搜索关键词"></el-input>
                </el-form-item>
                 <el-form-item label="权重">
                    <el-input v-model="weights" placeholder="请输入跳转链接"></el-input>
                </el-form-item>
            </el-form>
        </el-row>
        <div slot="footer" class="dialog-footer">
         <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="primary">确 定</el-button>
    </div>
    </el-dialog>
</div>

</template>
<script>
    let common = JSON.parse(window.localStorage.getItem('common'))
    import * as api from '@/config/api'
    export default {
        name: 'choiceDialog',
        props: {
            showExport: Boolean,
            platform: Object
        },
        data() {
            return {
                dialog: false,
                // platform_list: common.item_list_platform,
                platform_list: [{
                    type: '1',
                    name: '淘宝'
                }],
                location_list: common.item_position_list,
                classify_list: common.dtk_cid_list,

                id: '',
                platform_type: '',
                item_title: '',
                item_id: '',
                item_pic: '',
                end_price: '',
                coupon_amount: '',
                commission_rate: '',
                weights: '',
                category_id: '',
                status: '1',
                keyword: '',
                location_arr: [],
            }
        },
        watch: {
            platform(val) {
                if (val.id != undefined) {
                    this.id = val.id
                    this.platform_type = val.platform.toString()
                    this.item_title = val.item_title
                    this.item_id = val.item_id
                    this.item_pic = val.item_pic
                    this.end_price = val.end_price
                    this.coupon_amount = val.coupon_amount
                    this.commission_rate = val.commission_rate
                    this.weights = val.weights
                    this.category_id = val.category_id
                    this.status = val.status
                    this.keyword = val.keyword
                    let arry = [];
                    val.location_arr.some(item => {
                        arry.push(item.location_type.toString())
                    })
                    this.location_arr = arry
                } else {
                    this.id = ''
                    this.platform_type = ''
                    this.item_title = ''
                    this.item_id = ''
                    this.item_pic = ''
                    this.end_price = ''
                    this.coupon_amount = ''
                    this.commission_rate = ''
                    this.weights = ''
                    this.category_id = ''
                    this.status = ''
                    this.keyword = ''
                    this.location_arr = ''
                }
            },
            showExport(val) {
                this.dialog = val
            },
            dialog(val) {
                if (!val) {
                    this.close()
                }
            }
        },
        mounted() {

        },
        methods: {
            getOssToken() {
                api.getOssToken(res => {
                    this.ossToken = res.data.sign
                })
            },
            close() {
                this.$emit('clickClose', false)
            },
            imgUrl(val) {
                this.item_pic = val
            },
            // 添加修改
            primary() {
                if (this.id != '' && this.id != undefined) {
                    api.updateChoiceGoods({
                        id: this.id,
                        platform: this.platform_type,
                        item_title: this.item_title,
                        item_id: this.item_id,
                        item_pic: this.item_pic,
                        end_price: this.end_price,
                        coupon_amount: this.coupon_amount,
                        commission_rate: this.commission_rate,
                        weights: this.weights,
                        category_id: this.category_id,
                        status: this.status,
                        keyword: this.keyword,
                        location_arr: this.location_arr,
                    }, res => {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        })
                        this.$emit('clickClose', false)
                    })
                    return
                }
                api.addChoiceGoods({
                    platform: this.platform_type,
                    item_title: this.item_title,
                    item_id: this.item_id,
                    item_pic: this.item_pic,
                    end_price: this.end_price,
                    coupon_amount: this.coupon_amount,
                    commission_rate: this.commission_rate,
                    weights: this.weights,
                    category_id: this.category_id,
                    status: this.status,
                    keyword: this.keyword,
                    location_arr: this.location_arr,
                }, res => {
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    })
                    this.$emit('clickClose', false)
                })
            },
            // 根据id获取商品信息
            getGoodsInfo() {
                api.getGoodsInfo({
                    item_id: this.item_id,
                    platform: this.platform_type
                }, res => {
                    this.end_price = res.data.end_price
                    this.item_title = res.data.item_title
                    this.coupon_amount = res.data.discount_amount
                    this.item_pic = res.data.item_pic
                    this.commission_rate = res.data.commission_rate
                    this.category_id = res.data.cid
                })
            }
        }
    }
</script>
<style scoped>
    .el-button {
        margin-top: 10px
    }
    
    .el-select {
        float: left;
    }
    
    .export-box {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .3);
        z-index: 20;
    }
    
    .title {
        margin-bottom: 20px;
        text-align: left;
        font-weight: bold
    }
</style>