import axios from 'axios'
const OSS = require('ali-oss');

export function initOss(oss, dir, file) {
    console.log(oss)

    let client = new OSS({
        region: oss.region,
        //阿里云账号AccessKey拥有所有API的访问权限，建议遵循阿里云安全最佳实践，创建并使用STS方式访问API。
        accessKeyId: oss.access_key_id,
        accessKeySecret: oss.access_key_secret,
        stsToken: oss.security_token,
        bucket: oss.bucket
    });
    async function put() {
        try {
            // object表示上传到OSS的文件名称。
            // file表示浏览器中需要上传的文件，支持HTML5 file和Blob类型。
            let name = 'new_img_admin/' + dir + file.name
            let r1 = await client.put(name, file);
            return r1.url
        } catch (e) {
            console.error('error: %j', e);
        }
    }
    return put()
}