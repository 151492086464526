import _import from './_import'
// 日常维护
export default [{
    path: '/',
    component: () =>
        import ('@/pages/public/index/index.vue'),
    children: [{
        path: 'maintenance/platform',
        name: 'maintenanceEntryPlatform',
        component: () =>
            import ('@/pages/maintenance/platform/index.vue'),
        meta: {
            title: '平台页配置'
        }
    }, {
        path: 'maintenance/recommend',
        name: 'maintenanceRecommend',
        component: () =>
            import ('@/pages/maintenance/recommend/index.vue'),
        meta: {
            title: '推荐配置'
        }
    }, {
        path: 'maintenance/freeOrder',
        name: 'maintenanceFreeOrder',
        component: () =>
            import ('@/pages/maintenance/freeOrder/index.vue'),
        meta: {
            title: '免单商品'
        }
    }, {
        path: 'maintenance/commodityRecommend',
        name: 'maintenanceCommodityRecommend',
        component: () =>
            import ('@/pages/maintenance/commodityRecommend/index.vue'),
        meta: {
            title: '商品推荐'
        }
    }, {
        path: 'maintenance/choiceGoods',
        name: 'maintenanceChoiceGoods',
        component: () =>
            import ('@/pages/maintenance/choiceGoods/index.vue'),
        meta: {
            title: '人工选品'
        }
    }, {
        path: 'maintenance/lifeChannel',
        name: 'maintenanceLifeChannel',
        component: () =>
            import ('@/pages/maintenance/lifeChannel/index.vue'),
        meta: {
            title: '生活频道'
        }
    }, {
        path: 'maintenance/channelClassify',
        name: 'maintenanceChannelClassify',
        component: () =>
            import ('@/pages/maintenance/channelClassify/index.vue'),
        meta: {
            title: '频道分类'
        }
    }, {
        path: 'maintenance/actCommumity',
        name: 'maintenanceActCommumity',
        component: () =>
            import ('@/pages/maintenance/actCommumity/index.vue'),
        meta: {
            title: '社群淘礼金'
        }
    }, {
        path: 'maintenance/transfer',
        name: 'maintenanceTransfer',
        component: () =>
            import ('@/pages/maintenance/transfer/index.vue'),
        meta: { title: '中转页' }
    }, {
        path: 'maintenance/diamond',
        name: 'maintenanceDransfer',
        component: () =>
            import ('@/pages/maintenance/diamond/index.vue'),
        meta: { title: '钻石位' }
    }, {
        path: 'maintenance/advertise',
        name: 'maintenanceAdvertise',
        component: () =>
            import ('@/pages/maintenance/advertise/index.vue'),
        meta: { title: '活动宣传口' }
    }, {
        path: 'maintenance/configuration',
        name: 'maintenanceConfiguration',
        component: () =>
            import ('@/pages/maintenance/configuration/index.vue'),
        meta: { title: '单个配置项' }
    }, {
        path: 'maintenance/recharge',
        name: 'maintenanceRecharge',
        component: () =>
            import ('@/pages/maintenance/recharge/index.vue'),
        meta: { title: '用户充值' }
    }, {
        path: 'maintenance/rest',
        name: 'maintenanceRest',
        component: () =>
            import ('@/pages/maintenance/rest/index.vue'),
        meta: { title: '其他功能' }
    }, {
        path: 'maintenance/information',
        name: 'maintenanceInformation',
        component: () =>
            import ('@/pages/maintenance/information/index.vue'),
        meta: { title: '信息流管理' }
    }, {
        path: 'maintenance/search',
        name: 'maintenanceSearch',
        component: () =>
            import ('@/pages/maintenance/search/index.vue'),
        meta: { title: '搜索管理' }
    }, {
        path: 'maintenance/coupons',
        name: 'maintenanceCoupons',
        component: () =>
            import ('@/pages/maintenance/coupons/index.vue'),
        meta: { title: '优惠券管理' }
    }, {
        path: 'maintenance/activity',
        name: 'maintenanceActivity',
        component: () =>
            import ('@/pages/maintenance/activity/index.vue'),
        meta: { title: '活动管理' }
    }]
}]