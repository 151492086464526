<template>
<div v-show="showExport">
    <el-dialog title="添加/修改平台" :visible.sync="dialog" :close-on-click-modal=false>
        <el-row>
            <el-form label-width="120px">
                <el-form-item label="页面标题">
                    <el-input v-model="title" placeholder="请输入页面标题"></el-input>
                </el-form-item>
                 <el-form-item label="平台logo">
                     <upLoad dir="image/platform_page" id="up" @url="imgUrl" v-model="img"></upLoad>
                </el-form-item>
                 <el-form-item label="平台名称">
                    <el-input v-model="name" placeholder="请输入平台名称"></el-input>
                </el-form-item>
                 <el-form-item label="按钮文案">
                    <el-input v-model="button_copy" placeholder="请输入按钮文案"></el-input>
                </el-form-item>
                 <el-form-item label="跳转类型">
                    <el-select v-model="redirect_type" placeholder="请选择">
                        <el-option
                        v-for="item in redirect_type_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="安卓跳转链接">
                    <el-input v-model="android_redirect_url" placeholder="请输入跳转链接"></el-input>
                </el-form-item>
                 <el-form-item label="ios跳转链接">
                    <el-input v-model="ios_redirect_url" placeholder="请输入跳转链接"></el-input>
                </el-form-item>
                 <el-form-item label="携带参数">
                    <el-input v-model="bind_params" placeholder="请输入携带参数"></el-input>
                </el-form-item>
                 <el-form-item label="跳转标题">
                    <el-input v-model="redirect_title" placeholder="请输入跳转标题"></el-input>
                </el-form-item>
                 <el-form-item label="版本号限制">
                    <el-radio-group v-model="version_limit_type">
                        <el-radio v-for="item in version_limit_list" :label="item.type">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                 <el-form-item label="版本号">
                    <el-input v-model="version" placeholder="请输入版本号"></el-input>
                </el-form-item>
            </el-form>
        </el-row>
        <div slot="footer" class="dialog-footer">
         <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="primary">确 定</el-button>
    </div>
    </el-dialog>
</div>

</template>
<script>
    import * as api from '@/config/api'
    export default {
        name: 'platformDialog',
        props: {
            showExport: Boolean,
            platform: Object
        },
        data() {
            return {
                ossToken: {},
                redirect_type_list: [],
                version_limit_list: [],
                dialog: false,
                id: '',
                img: '',
                title: '',
                name: '',
                logo: '',
                button_copy: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
                bind_params: '',
                redirect_title: '',
                version_limit_type: '',
                version: '',
            }
        },
        watch: {
            platform(val) {
                this.id = val.id
                this.title = val.title
                this.name = val.name
                this.logo = val.logo
                this.button_copy = val.button_copy
                this.redirect_type = val.redirect_type
                this.android_redirect_url = val.android_redirect_url
                this.ios_redirect_url = val.ios_redirect_url
                this.bind_params = val.bind_params
                this.redirect_title = val.redirect_title
                this.version_limit_type = val.version_limit_type
                this.version = val.version
            },
            logo(val) {
                this.img = val
            },
            showExport(val) {
                this.dialog = val
            },
            dialog(val) {
                if (!val) {
                    this.close()
                }
            }
        },
        mounted() {
            let common = JSON.parse(window.localStorage.getItem('common'))
            if (common) {
                this.redirect_type_list = common.redirect_type_list
                this.version_limit_list = common.compare_type_list
            } else {
                api.getCommon()
            }
        },
        methods: {
            getOssToken() {
                api.getOssToken(res => {
                    this.ossToken = res.data.sign
                })
            },
            close() {
                this.$emit('clickClose', false)
            },
            imgUrl(val) {
                this.logo = val
            },
            primary() {
                console.log(this.id)
                if (this.id != '' && this.id != undefined) {
                    api.updatePlatformPage({
                        'id': this.id,
                        "title": this.title,
                        "name": this.name,
                        "logo": this.logo,
                        "button_copy": this.button_copy,
                        "redirect_type": this.redirect_type,
                        "android_redirect_url": this.android_redirect_url,
                        "ios_redirect_url": this.ios_redirect_url,
                        "bind_params": this.bind_params,
                        "redirect_title": this.redirect_title,
                        "version_limit_type": this.version_limit_type,
                        "version": this.version,
                    }, res => {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        })
                        this.$emit('clickClose', false)
                    })
                    return
                }
                api.postPlatformPage({
                    "title": this.title,
                    "name": this.name,
                    "logo": this.logo,
                    "button_copy": this.button_copy,
                    "redirect_type": this.redirect_type,
                    "android_redirect_url": this.android_redirect_url,
                    "ios_redirect_url": this.ios_redirect_url,
                    "bind_params": this.bind_params,
                    "redirect_title": this.redirect_title,
                    "version_limit_type": this.version_limit_type,
                    "version": this.version,
                }, res => {
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    })
                    this.$emit('clickClose', false)
                })
            },
        }
    }
</script>
<style scoped>
    .el-button {
        margin-top: 10px
    }
    
    .el-select {
        float: left;
    }
    
    .export-box {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .3);
        z-index: 20;
    }
    
    .title {
        margin-bottom: 20px;
        text-align: left;
        font-weight: bold
    }
</style>