import _import from './_import';
// 明细管理
export default [
  {
    path: '/',
    component: () => import('@/pages/public/index/index.vue'),
    children: [
      {
        path: 'particulars/orderList',
        name: 'particularsOrderList',
        component: () => import('@/pages/particulars/orderList/index.vue'),
        meta: {
          title: '订单明细',
        },
      },
      {
        path: 'particulars/income',
        name: 'particularsIncome',
        component: () => import('@/pages/particulars/incomeList/index.vue'),
        meta: {
          title: '收入明细',
        },
      },
      {
        path: 'particulars/withdraw',
        name: 'particularsWithdraw',
        component: () => import('@/pages/particulars/withdrawList/index.vue'),
        meta: {
          title: '提现明细',
        },
      },
      {
        path: 'particulars/pddOrder',
        name: 'particularsPddOrder',
        component: () => import('@/pages/particulars/pddOrder/index.vue'),
        meta: {
          title: '拼多多订单',
        },
      },
      {
        path: 'particulars/authorization',
        name: 'particularsAuthorization',
        component: () =>
          import('@/pages/particulars/authorizationList/index.vue'),
        meta: {
          title: '授权明细',
        },
      },
      {
        path: 'particulars/member',
        name: 'particularsMember',
        component: () => import('@/pages/particulars/memberList/index.vue'),
        meta: {
          title: '会员明细',
        },
      },
      {
        path: 'particulars/jdOrder',
        name: 'particularsJdOrder',
        component: () => import('@/pages/particulars/jdOrder/index.vue'),
        meta: {
          title: '京东订单',
        },
      },
      {
        path: 'particulars/snOrder',
        name: 'particularsSnOrder',
        component: () => import('@/pages/particulars/snOrder/index.vue'),
        meta: {
          title: '苏宁订单',
        },
      },
      {
        path: 'particulars/blackList',
        name: 'particularsBlackList',
        component: () => import('@/pages/particulars/blackList/index.vue'),
        meta: {
          title: '提现黑名单',
        },
      },
      {
        path: 'particulars/mtMember',
        name: 'particularsMtMember',
        component: () => import('@/pages/particulars/mtMemberList/index.vue'),
        meta: {
          title: '美团会员明细',
        },
      },
      {
        path: 'particulars/jpush',
        name: 'particularsJpush',
        component: () => import('@/pages/particulars/jpushList/index.vue'),
        meta: {
          title: '极光RID',
        },
      },
      {
        path: 'particulars/vipOrder',
        name: 'particularsVipOrder',
        component: () => import('@/pages/particulars/vipOrder/index.vue'),
        meta: {
          title: '唯品会订单',
        },
      },
      {
        path: 'particulars/mtOrder',
        name: 'particularsMtOrder',
        component: () => import('@/pages/particulars/mtOrder/index.vue'),
        meta: {
          title: '美团订单',
        },
      },
      {
        path: 'particulars/illegalOrder',
        name: 'particularsIllegalOrder',
        component: () => import('@/pages/particulars/illegalOrder/index.vue'),
        meta: {
          title: '违规订单',
        },
      },
      {
        path: 'particulars/selectionOrder',
        name: 'particularsSelectionOrder',
        component: () => import('@/pages/particulars/selectionOrder/index.vue'),
        meta: {
          title: '选品订单',
        },
      },
      {
        path: 'particulars/subsidizeOrder',
        name: 'subsidizeOrder',
        component: () => import('@/pages/particulars/subsidiesList/index.vue'),
        meta: {
          title: '补贴订单',
        },
      },
      {
        path: 'particulars/ddOrder',
        name: 'ddOrder',
        component: () => import('@/pages/particulars/ddOrder/index.vue'),
        meta: {
          title: '抖店订单',
        },
      },
      {
        path: 'particulars/ksOrder',
        name: 'ksOrder',
        component: () => import('@/pages/particulars/ksOrder/index.vue'),
        meta: {
          title: '快手订单',
        },
      },
      {
        path: 'particulars/elmOrder',
        name: 'elmOrder',
        component: () => import('@/pages/particulars/elmOrder/index.vue'),
        meta: {
          title: '饿了么订单',
        },
      },
      {
        path: 'particulars/realNameList',
        name: 'realNameList',
        component: () => import('@/pages/particulars/realNameList/index.vue'),
        meta: {
          title: '清除实名',
        },
      },
      {
        path: 'particulars/whiteList',
        name: 'whiteList',
        component: () => import('@/pages/particulars/whiteList/index.vue'),
        meta: {
          title: '提现白名单',
        },
      },
    ],
  },
];
