import _import from './_import'
// 商品库
export default {
    path: '/',
    component: () =>
        import('@/pages/public/index/index.vue'),
    children: [{
        path: 'goodLib/list',
        name: 'goodLibList',
        component: () =>
            import('@/pages/goodlib/list/index.vue'),
        meta: {
            title: '商品库'
        }
    }]
}