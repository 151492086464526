import _import from './_import'
// 活动管理
export default {
    path: '/',
    component: () =>
        import ('@/pages/public/index/index.vue'),
    children: [{
        path: 'activity/maintenance',
        name: 'activityMaintenance',
        component: () =>
            import ('@/pages/activity/activityList/index.vue'),
        meta: {
            title: '活动管理'
        }
    }, {
        path: 'activity/snatchTreasureGoods',
        name: 'activitySnatchTreasureGoods',
        component: () =>
            import ('@/pages/activity/snatchTreasure/goods/index.vue'),
        meta: {
            title: '夺宝活动商品管理'
        }
    }, {
        path: 'activity/snatchTreasureActivity',
        name: 'activitySnatchTreasureActivity',
        component: () =>
            import ('@/pages/activity/snatchTreasure/list/index.vue'),
        meta: {
            title: '夺宝活动列表'
        }
    }, {
        path: 'activity/snatchTreasureUser',
        name: 'activitySnatchTreasureUser',
        component: () =>
            import ('@/pages/activity/snatchTreasure/user/index.vue'),
        meta: {
            title: '风险用户列表'
        }
    }, {
        path: 'activity/snatchTreasureMdms',
        name: 'activitySnatchTreasureMdms',
        component: () =>
            import ('@/pages/activity/snatchTreasure/MDMS/index.vue'),
        meta: {
            title: '夺宝活动数据'
        }
    }, {
        path: 'activity/associationList',
        name: 'associationList',
        component: () =>
            import ('@/pages/activity/association/list/index.vue'),
        meta: {
            title: '奖池列表'
        }
    }, {
        path: 'activity/associationData',
        name: 'associationData',
        component: () =>
            import ('@/pages/activity/association/detail/index.vue'),
        meta: {
            title: '社群详情数据'
        }
    }, {
        path: 'activity/associationUser',
        name: 'associationUser',
        component: () =>
            import ('@/pages/activity/association/user/index.vue'),
        meta: {
            title: '社群用户列表'
        }
    }, {
        path: 'activity/promotion',
        name: 'promotionChannel',
        component: () =>
            import ('@/pages/activity/promotion/index.vue'),
        meta: {
            title: '渠道信息管理'
        }
    }, {
        path: 'activity/DoubleEleven',
        name: 'DoubleEleven',
        component: () =>
            import ('@/pages/activity/doubleEleven/set/index.vue'),
        meta: {
            title: '双十一预算商品管理'
        }
    }, {
        path: 'activity/DoubleElevenData',
        name: 'DoubleElevenData',
        component: () =>
            import ('@/pages/activity/doubleEleven/detail/index.vue'),
        meta: {
            title: '双十一预算详情数据'
        }
    }, {
        path: 'activity/DoubleEleven/user',
        name: 'DoubleElevenUser',
        component: () =>
            import ('@/pages/activity/doubleEleven/user/index.vue'),
        meta: {
            title: '双十一绑定数据'
        }
    }]
}