import _import from './_import'
// 概述
export default [{
    path: '/',
    component: () =>
        import ('@/pages/public/index/index.vue'),
    children: [{
        path: 'gzh/commission',
        name: 'gzhCommission',
        component: () =>
            import ('@/pages/gzh/gzhCommission/index.vue'),
        meta: {
            title: '推广位佣金配置'
        },
    }, {
        path: 'gzh/xxgwzs',
        name: 'xxgwzs',
        component: () =>
            import ('@/pages/gzh/xxgwzs/index.vue'),
        meta: {
            title: '小熊购物助手'
        },
    }, {
        path: 'gzh/xmxyfl',
        name: 'xmxyfl',
        component: () =>
            import ('@/pages/gzh/xmxyfl/index.vue'),
        meta: {
            title: '小蛮熊有福利'
        },
    }, {
        path: 'gzh/xxgwth',
        name: 'xxgwth',
        component: () =>
            import ('@/pages/gzh/xxgwth/index.vue'),
        meta: {
            title: '小熊购物特惠'
        },
    }, {
        path: 'gzh/xxtyh',
        name: 'xxtyh',
        component: () =>
            import ('@/pages/gzh/xxtyh/index.vue'),
        meta: {
            title: '小熊淘优惠'
        },
    }, {
        path: 'gzh/xmxflzx',
        name: 'xmxflzx',
        component: () =>
            import ('@/pages/gzh/xmxflzx/index.vue'),
        meta: {
            title: '小蛮熊福利中心'
        },
    }, {
        path: 'gzh/xxgwtj',
        name: 'xxgwtj',
        component: () =>
            import ('@/pages/gzh/xxgwtj/index.vue'),
        meta: {
            title: '小熊购物特价'
        },
    }, {
        path: 'gzh/dbsq',
        name: 'dbsq',
        component: () =>
            import ('@/pages/gzh/dbsq/index.vue'),
        meta: {
            title: '餐前外卖券'
        },
    }, {
        path: 'gzh/xxyhh',
        name: 'xxyhh',
        component: () =>
            import ('@/pages/gzh/xxyhh/index.vue'),
        meta: {
            title: '小熊有好货'
        },
    }, {
        path: 'gzh/wmdeqy',
        name: 'wmdeqy',
        component: () =>
            import ('@/pages/gzh/wmdeqy/index.vue'),
        meta: {
            title: '外卖大额券压'
        },
    }]
}]