import login from '@/router/login'
import home from '@/router/home'
import account from '@/router/account'
import maintenance from '@/router/maintenance'
import particulars from '@/router/particulars'
import activity from '@/router/activity'
import goodPrice from '@/router/goodPrice'
import goodLib from '@/router/goodLib'
import commodity from '@/router/commodity'
import assets from '@/router/assets'
import MDMS from '@/router/MDMS'
import rest from '@/router/rest'
import gzh from '@/router/gzh'
export default [{
        path: '*',
        redirect: '404/entry'
    },
    {
        path: '/',
        redirect: 'home/entry'
    }
].concat(home, login, account, maintenance, particulars, activity, goodPrice, commodity, goodLib, assets, MDMS, rest, gzh)