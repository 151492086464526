<template>
  <div class="upload-box">
    <div class="img-box" v-loading="loading">
      <label :for="up">
        <input type="file" name="" :id="up" @change="uploadImg" />
        <el-input type="text" v-model="imgUrl" @change="changeText">
          <el-tooltip placement="top" slot="prepend">
            <div slot="content">
              <img :src="imgUrl" class="show_img" />
            </div>
            <el-button icon="el-icon-picture-outline" v-show="imgUrl != ''">
            </el-button>
          </el-tooltip>
        </el-input>
        <el-button type="primary" @click="uploadBtn">点击上传</el-button>
      </label>
    </div>
  </div>
</template>
<script></script>
<script>
    import * as api from "@/config/api";
    import * as imgUpload from "@/config/upload";
    export default {
        name: "upload",
        props: {
            id: String,
            dir: String,
            img: String,
            upIndex: Number,
        },
        data() {
            return {
                ossToken: {},
                imgUrl: this.img,
                address: "",
                index: this.upIndex,
                up: this.id,
                loading: false,

            };
        },
        model: {
            prop: "img",
            event: "change",
        },
        watch: {
            img(val) {
                this.imgUrl = val;
            },
            imgUrl(val) {
                if (this.index === undefined) {
                    this.$emit("url", this.imgUrl);
                    return;
                }
                this.$emit("url", {
                    url: this.imgUrl,
                    index: this.index,
                });
            },
        },
        mounted() {},
        methods: {
            uploadImg() {
                this.loading = true;
                let file = document.getElementById(`${this.up}`).files;

                if (file[0] === undefined) {
                    this.loading = false;
                    return;
                }
                let type = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']
                if (type.indexOf(file[0].type) == -1) {
                    console.log(file[0].type)
                    file = []
                    this.loading = false;
                    this.$message.error('请选择图片文件');
                    return;
                }
                let width, height;
                let URL = window.URL || window.webkitURL
                let img = new Image()
                img.src = URL.createObjectURL(file[0])
                img.onload = function() {
                    height = this.height
                    width = this.width
                }
                let formData = new FormData();
                formData.append("file", file[0]);
                api.upLoadImg(formData, true, (res) => {
                    this.imgUrl = res.data.url;
                    this.loading = false;
                    this.$emit('size', {
                        width,
                        height
                    })
                    if (this.index === undefined) {
                        this.$emit("url", this.imgUrl);
                        this.$emit("change", this.imgUrl);
                        return;
                    }
                    this.$emit("change", {
                        url: this.imgUrl,
                        index: this.index,
                    });
                    this.$emit("url", {
                        url: this.imgUrl,
                        index: this.index,
                    });
                });
            },
            changeText() {
                if (this.index === undefined) {
                    this.$emit("url", this.imgUrl);
                    this.$emit("change", this.imgUrl);
                    return;
                }
                this.$emit("change", {
                    url: this.imgUrl,
                    index: this.index,
                });
                this.$emit("url", {
                    url: this.imgUrl,
                    index: this.index,
                });
            },
            uploadBtn() {
                document.getElementById(`${this.up}`).click();
            },
        },
    };
</script>
<style scoped>
    input[type="file"] {
        display: none;
    }
    
    .img-box label {
        display: inline-block;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    
    .img-box label i {
        line-height: 100px;
    }
    
    .img-box .el-input {
        width: auto;
    }
    
    .show_img {
        width: 200px;
    }
</style>