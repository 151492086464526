import _import from './_import'
// 概述
export default [{
    path: '/account',
    component: () =>
        import ('@/pages/public/index/index.vue'),
    children: [{
        path: '/account/management',
        name: 'accountManagement',
        component: () =>
            import ('@/pages/account/management/index.vue'),
        meta: {
            title: '账户管理'
        }
    }, {
        path: "/account/jurisdiction",
        name: 'accountJurisdiction',
        component: () =>
            import ('@/pages/account/jurisdiction'),
        meta: {
            title: '角色列表'
        }
    }, {
        path: "/account/menuList",
        name: 'accountMenuList',
        component: () =>
            import ('@/pages/account/menuList'),
        meta: {
            title: '菜单列表'
        }
    }, {
        path: "/account/permissionList",
        name: 'accountPermissionList',
        component: () =>
            import ('@/pages/account/permissionList'),
        meta: {
            title: '权限列表'
        }
    }]
}]