import _import from './_import'
// 概述
export default [{
    path: '/',
    component: () =>
        import ('@/pages/public/index/index.vue'),
    children: [{
        path: 'rest/black',
        name: 'restBlack',
        component: () =>
            import ('@/pages/rest/blacklist/index.vue'),
        meta: {
            title: '店铺黑名单'
        },
    }]
}]