// 好价管理
import _import from './_import'
// 好价管理
export default {
    path: '/',
    component: () =>
        import ('@/pages/public/index/index.vue'),
    children: [{
        path: 'goodprice/detail',
        name: 'goodPricedetail',
        component: () =>
            import ('@/pages/goodprice/detail/index.vue'),
        meta: {
            title: '好价明细'
        }
    }, {
        path: 'goodprice/classify',
        name: 'goodPeiceClassify',
        component: () =>
            import ('@/pages/goodprice/classify/index.vue'),
        meta: {
            title: '分类管理'
        }
    }, {
        path: 'goodprice/report',
        name: 'goodPriceReport',
        component: () =>
            import ('@/pages/goodprice/report/index.vue'),
        meta: {
            title: '优惠举报'
        }
    }, ]
}