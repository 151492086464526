<template>
    <div v-show="showExport">
        <el-dialog title="添加/修改平台" width="80%" :visible.sync="dialog" :close-on-click-modal=false>
            <el-steps :active="active" finish-status="success" simple>
                <el-step title="步骤 1"></el-step>
                <el-step title="步骤 2"></el-step>
            </el-steps>
            <div class="from" v-show="active == 0">
                <el-radio-group v-model="channel">
                    <el-radio v-for="item in channel_type_list" :label="item.type">{{ item.name }}</el-radio>
                </el-radio-group>
            </div>
            <div class="from" v-show="active == 1">
                <el-row>
                    <el-form label-width="120px">
                        <el-form-item label="频道名称">
                            <el-input v-model="channel_name" placeholder=""></el-input>
                        </el-form-item>
                        <el-form-item label="背景图">
                            <upLoad id="up" @size="imgUrl" v-model="image"></upLoad>
                        </el-form-item>
                        <div v-show="channel == 4 || channel == 5 || channel == 10 || channel == 7">
                            <el-form-item :label="channel != 10 ? '专题ID' : '好价专题ID'" v-show="channel != 7">
                                <el-input v-model="topic_id" type="number" placeholder=""></el-input>
                            </el-form-item>
                            <el-form-item label="跳转类型">
                                <el-select v-model="redirect_type" placeholder="请选择">
                                    <el-option v-for="item in redirect_type_list" :key="item.type" :label="item.name"
                                        :value="item.type">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="安卓跳转链接">
                                <el-input v-model="android_redirect_url" placeholder="请输入跳转链接"></el-input>
                            </el-form-item>
                            <el-form-item label="ios跳转链接">
                                <el-input v-model="ios_redirect_url" placeholder="请输入跳转链接"></el-input>
                            </el-form-item>
                            <el-form-item label="携带参数">
                                <el-input v-model="bind_params" placeholder="请输入携带参数"></el-input>
                            </el-form-item>
                        </div>
                        <div v-show="channel == 11">
                            <el-form-item v-for="(item, index) in functionList" :label="'位置' + (index + 1)">
                                <el-row>
                                    <el-col :span="8">
                                        <upLoadText :id="'up' + index" v-model="item.image"></upLoadText>
                                    </el-col>
                                    <el-col :span="4">
                                        <el-select v-model="item.redirect_type" placeholder="请选择">
                                            <el-option v-for="item in redirect_type_list" :key="item.type"
                                                :label="item.name" :value="item.type">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-input v-model="item.android_redirect_url"
                                            placeholder="请输入安卓跳转链接"></el-input>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-input v-model="item.ios_redirect_url" placeholder="请输入ios跳转链接"></el-input>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </div>
                        <div v-show="channel == 12">
                            <el-form-item v-for="(item, index) in promotionList" :label="'位置' + (index + 1)">
                                <el-row>
                                    <el-col :span="8">
                                        <upLoadText :id="'Up' + index" v-model="item.image"></upLoadText>
                                    </el-col>
                                    <el-col :span="4">
                                        <el-select v-model="item.redirect_type" placeholder="请选择">
                                            <el-option v-for="item in redirect_type_list" :key="item.type"
                                                :label="item.name" :value="item.type">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-input v-model="item.android_redirect_url"
                                            placeholder="请输入安卓跳转链接"></el-input>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-input v-model="item.ios_redirect_url" placeholder="请输入ios跳转链接"></el-input>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </div>
                        <el-form-item label="时间范围">
                            <el-date-picker v-model="start_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                                placeholder="选择日期时间">
                            </el-date-picker>
                            -
                            <el-date-picker v-model="end_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                                placeholder="选择日期时间">
                            </el-date-picker>
                            <el-button type="primary" @click="add_td_tiem">特定时间</el-button>
                        </el-form-item>
                        <el-form-item label="" v-for="(item, index) in specific_time" :key="index">
                            <el-time-picker is-range v-model="specific_time[index]" value-format="HH:mm" format="HH:mm"
                                range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
                                placeholder="选择时间范围">
                            </el-time-picker>
                            <el-button type="danger" @click="del_specific_time_item(index)">删除</el-button>
                        </el-form-item>
                        <el-form-item label="是否显示">
                            <el-radio-group v-model="status">
                                <el-radio label="1">显示</el-radio>
                                <el-radio label="2">隐藏</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="显示类型">
                            <el-radio-group v-model="show_type">
                                <el-radio v-for="item in showList" :key="item.type" :label="item.type">{{ item.name
                                    }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="版本号限制">
                            <el-radio-group v-model="version_type">
                                <el-radio v-for="item in version_limit_list" :label="item.type">{{ item.name
                                    }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="版本号">
                            <el-input v-model="version" placeholder="请输入版本号"></el-input>
                        </el-form-item>
                        <el-form-item label="审核是否显示">
                            <el-radio-group v-model="is_show">
                                <el-radio label="1">显示</el-radio>
                                <el-radio label="2">隐藏</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="投放位置">
                            <el-radio-group v-model="location_type">
                                <el-radio v-for="item in location_type_list" :key="item.type" :label="item.type">{{
                                    item.name }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="用户分群">
                            <el-select v-model="group_id" multiple placeholder="">
                                <el-option v-for="item in groupList" :key="item.type" :label="item.name"
                                    :value="item.type"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="行为">
                            <el-select v-model="behavior_type" placeholder="">
                                <el-option v-for="item in behaviorList" :key="item.type" :label="item.name"
                                    :value="item.type"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="最近x天">
                            <el-input v-model="day_number" placeholder=""></el-input>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" v-if="active == 1" @click="primary">确 定</el-button>
                <el-button type="primary" v-else @click="next">下一步</el-button>

            </div>
        </el-dialog>
    </div>

</template>
<script>
let common = JSON.parse(window.localStorage.getItem('common'))
import * as api from '@/config/api'
export default {
    name: 'informationDialog',
    props: {
        showExport: Boolean,
        platform: Object
    },
    data() {
        return {
            active: 0,

            channel_type_list: common.channel_type_list,
            channel: '',

            ossToken: {},

            location_type_list: [
                {
                    type: '-1',
                    name: '精选',
                },
                {
                    type: '1',
                    name: '淘宝',
                },
                {
                    type: '3',
                    name: '拼多多',
                },
                {
                    type: '2',
                    name: '京东',
                },
                {
                    type: '4',
                    name: '唯品会',
                },
                {
                    type: '9',
                    name: '抖音',
                },
                {
                    type: '12',
                    name: '快手',
                },
            ],
            location_type: -1,

            redirect_type_list: [],
            version_limit_list: [],
            groupList: common.user_group_list,
            behaviorList: [...[{
                type: '0',
                name: '不限'
            }], ...common.user_behavior_list],
            showList: common.show_type_list,

            dialog: false,
            id: '',

            channel_name: '',
            image: '',
            topic_id: '',
            redirect_type: '',
            android_redirect_url: '',
            ios_redirect_url: '',
            bind_params: '',
            redirect_title: '',
            version_type: '',
            version: '',
            start_time: "",
            end_time: "",
            specific_time: [],
            status: '',
            group_id: "",
            is_show: "",
            day_number: "",
            behavior_type: "",
            show_type: '',

            functionList: [{
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }, {
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }, {
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }],
            promotionList: [{
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }, {
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }, {
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }, {
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }],
            image_width: '',
            image_height: '',
            channel_position: []
        }
    },
    watch: {
        platform(val) {

            console.log(val.position)

            val.channel_type != undefined ? this.active = 1 : this.active = 0
            this.channel = val.channel_type
            this.channel_name = val.channel_name
            this.id = val.id
            this.image = val.image
            this.topic_id = val.topic_id
            this.redirect_type = val.redirect_type
            this.android_redirect_url = val.android_redirect_url
            this.ios_redirect_url = val.ios_redirect_url
            this.bind_params = val.bind_params
            this.redirect_title = val.redirect_title
            this.version_type = val.version_type
            this.version = val.version
            this.location_type = val.location_type

            this.start_time = Number(val.start_time).isNaN != undefined ? '' : val.start_time != undefined ? this.getTime(Number(val.start_time)) : '';
            this.end_time = Number(val.end_time).isNaN != undefined ? '' : val.end_time != undefined ? this.getTime(Number(val.end_time)) : '';
            let specific_time = val.specific_time;
            let specific_time_arr = [];
            if (specific_time) {
                console.log(specific_time)
                specific_time.forEach((item, index) => {
                    specific_time_arr.push([item.start_time, item.end_time]);
                });
                this.specific_time = specific_time_arr;
            } else {
                this.specific_time = []
            }
            this.status = val.status
            this.group_id = val.group_id != undefined && val.group_id != '' ? val.group_id.split(',') : ''
            this.is_show = val.is_show
            this.day_number = val.day_number
            this.behavior_type = val.behavior_type
            this.show_type = val.show_type

            this.image_width = val.image_width
            this.image_height = val.image_height
            val.position != undefined ? this.functionList = val.position : this.functionList = [{
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }, {
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }, {
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }]
            val.position != undefined ? this.promotionList = val.position : this.promotionList = [{
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }, {
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }, {
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }, {
                image: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
            }]
        },
        logo(val) {
            this.img = val
        },
        showExport(val) {
            this.dialog = val
        },
        dialog(val) {
            if (!val) {
                this.close()
            }
        },
        channel(val) {
            console.log(val)
        }
    },
    mounted() {
        let common = JSON.parse(window.localStorage.getItem('common'))
        if (common) {
            this.redirect_type_list = common.redirect_type_list
            this.version_limit_list = common.compare_type_list
        } else {
            api.getCommon()
        }
    },
    methods: {
        getTime(time) {
            let Time = new Date(time * 1000);
            return `${Time.getFullYear()}-${Time.getMonth() + 1 < 10 ? "0" + (Time.getMonth() + 1) : Time.getMonth() + 1}-${Time.getDate() < 10 ? "0" + Time.getDate() : Time.getDate()} ${Time.getHours() < 10 ? "0" + Time.getHours() : Time.getHours()}:${Time.getMinutes() < 10 ? "0" + Time.getMinutes() : Time.getMinutes()}:${Time.getSeconds() < 10 ? "0" + Time.getSeconds() : Time.getSeconds()}`
        },
        close() {
            this.active = 0
            this.$emit('clickClose', false)
        },
        imgUrl(val) {
            //     this.logo = val
            this.image_width = val.width
            this.image_height = val.height
        },
        next() {
            // if (this.active++ > 2) this.active = 0;
            if (this.active == 0 && this.channel == '') {
                this.$message({
                    type: 'error',
                    message: '请选择类型'
                })
            } else {
                this.active++
            }
        },
        //   添加特定时间
        add_td_tiem() {
            this.specific_time.push(['', ''])
        },
        //   删除特定时间
        del_specific_time_item(index) {
            this.specific_time.splice(index, 1);
        },
        primary() {
            if (!this.detection()) {
                return
            }
            this.channel == 11 ? this.channel_position = this.getPromotion() : this.channel == 12 ? this.channel_position = this.getPromotion() : this.channel_position = ''
            let specific_time = [];
            this.specific_time.forEach(item => {
                specific_time.push({
                    start_time: item[0],
                    end_time: item[1],
                })
            })
            if (this.id != '' && this.id != undefined) {
                api.updateInformation({
                    id: this.id,
                    channel_type: this.channel,
                    channel_name: this.channel_name,
                    image: this.image,
                    topic_id: this.topic_id,
                    redirect_type: this.redirect_type,
                    android_redirect_url: this.android_redirect_url,
                    ios_redirect_url: this.ios_redirect_url,
                    bind_params: this.bind_params,
                    redirect_title: this.redirect_title,
                    version_type: this.version_type,
                    version: this.version,
                    start_time: this.start_time,
                    end_time: this.end_time,
                    specific_time: specific_time,
                    status: this.status,
                    group_id: this.group_id,
                    is_show: this.is_show,
                    day_number: this.day_number,
                    behavior_type: this.behavior_type,
                    show_type: this.show_type,
                    channel_position: this.channel_position,
                    image_width: this.image_width,
                    image_height: this.image_height,
                    location_type: this.location_type
                }, res => {
                    this.$message({
                        message: '修改成功',
                        type: 'success'
                    })
                    this.$emit('clickClose', false)
                })
                return
            }
            api.addInformation({
                channel_type: this.channel,
                channel_name: this.channel_name,
                image: this.image,
                topic_id: this.topic_id,
                redirect_type: this.redirect_type,
                android_redirect_url: this.android_redirect_url,
                ios_redirect_url: this.ios_redirect_url,
                bind_params: this.bind_params,
                redirect_title: this.redirect_title,
                version_type: this.version_type,
                version: this.version,
                start_time: this.start_time,
                end_time: this.end_time,
                specific_time: specific_time,
                status: this.status,
                group_id: this.group_id,
                is_show: this.is_show,
                day_number: this.day_number,
                behavior_type: this.behavior_type,
                show_type: this.show_type,
                channel_position: this.channel_position,
                image_width: this.image_width,
                image_height: this.image_height,
                location_type: this.location_type
            }, res => {
                this.$message({
                    message: '添加成功',
                    type: 'success'
                })
                this.$emit('clickClose', false)
            })
        },
        // 检测功能推荐、大促会场
        detection() {
            if (this.channel == 11) {
                for (let i = 0; i < this.functionList.length; i++) {
                    if (this.functionList[i].image == '' || this.functionList[i].redirect_type == '' || this.functionList[i].ios_redirect_url == '' || this.functionList[i].android_redirect_url == '') {
                        this.$message.error('3个位置数据都必须填')
                        return false
                    }
                }
                return true
            } else if (this.channel == 12) {
                let num = 0
                for (let i = 0; i < this.promotionList.length; i++) {
                    if (this.promotionList[i].image != '' && this.promotionList[i].redirect_type != '' && this.promotionList[i].ios_redirect_url != '' && this.promotionList[i].android_redirect_url != '') {
                        num++
                    }
                }
                if (num == 0 || num % 2 != 0) {
                    this.$message.error('位置数据要么填2个或填4个')
                    return false
                }
                return true
            } else {
                return true
            }
        },
        getPromotion() {
            let arr = []
            if (this.channel == 11) {
                for (let i = 0; i < this.functionList.length; i++) {
                    if (this.functionList[i].image != '' && this.functionList[i].redirect_type != '' && this.functionList[i].ios_redirect_url != '' && this.functionList[i].android_redirect_url != '') {
                        arr.push(this.functionList[i])
                    }
                }
            } else {
                for (let i = 0; i < this.promotionList.length; i++) {
                    if (this.promotionList[i].image != '' && this.promotionList[i].redirect_type != '' && this.promotionList[i].ios_redirect_url != '' && this.promotionList[i].android_redirect_url != '') {
                        arr.push(this.promotionList[i])
                    }
                }
            }

            return arr
        }
    }
}
</script>
<style scoped>
.el-button {
    margin-top: 10px
}

.el-select {
    float: left;
}

.export-box {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .3);
    z-index: 20;
}

.title {
    margin-bottom: 20px;
    text-align: left;
    font-weight: bold
}

.from {
    margin-top: 30px;
}
</style>