<template>
<div v-show="showExport">
    <el-dialog title="添加/修改平台" :visible.sync="dialog" :close-on-click-modal=false>
        <el-row>
            <el-form label-width="120px">

                <el-form-item label="入口名称">
                    <el-input v-model="name" placeholder="请输入入口名称"></el-input>
                </el-form-item>
                <el-form-item label="入口图片">
                    <upLoadText @url="imgUrl" id="up" v-model="icon_url"></upLoadText>
                </el-form-item>
                <el-form-item label="跳转类型">
                    <el-select v-model="redirect_type" placeholder="请选择">
                        <el-option
                        v-for="item in redirect_type_list"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="安卓跳转链接">
                    <el-input v-model="android_redirect_url" placeholder="请输入跳转链接"></el-input>
                </el-form-item>
                 <el-form-item label="ios跳转链接">
                    <el-input v-model="ios_redirect_url" placeholder="请输入跳转链接"></el-input>
                </el-form-item>
                 <el-form-item label="携带参数">
                    <el-input v-model="bind_params" placeholder="请输入携带参数"></el-input>
                </el-form-item>
                 <el-form-item label="跳转标题">
                    <el-input v-model="title" placeholder="请输入跳转标题"></el-input>
                </el-form-item>
                 <el-form-item label="功能权重">
                    <el-input v-model="weight" type="number" placeholder="请输入跳转标题"></el-input>
                </el-form-item>
                 <el-form-item label="是否显示">
                     <el-radio-group v-model="status">
                        <el-radio v-for="item in recommend" :label="item.type">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="频道分类">
                    <el-select v-model="channel_id" placeholder="请选择">
                        <el-option
                        v-for="item in channel_list"
                        :key="item.id"
                        :label="item.channel_name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                 <el-form-item label="版本号限制">
                    <el-radio-group v-model="version_type">
                        <el-radio v-for="item in version_limit_list" :label="item.type">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                 <el-form-item label="版本号">
                    <el-input v-model="version" placeholder="请输入版本号"></el-input>
                </el-form-item>
                <el-form-item label="审核模式">
                    <el-radio-group v-model="is_show">
                        <el-radio v-for="item in is_version" :label="item.type">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="是否推荐">
                    <el-radio-group v-model="is_recommend">
                        <el-radio v-for="item in recommend" :label="item.type">{{item.name}}</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
        </el-row>
        <div slot="footer" class="dialog-footer">
         <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="primary">确 定</el-button>
    </div>
    </el-dialog>
</div>

</template>
<script>
    let common = JSON.parse(window.localStorage.getItem('common'))
    import * as api from '@/config/api'
    export default {
        name: 'choiceDialog',
        props: {
            showExport: Boolean,
            platform: Object
        },
        data() {
            return {
                dialog: false,
                redirect_type_list: common.redirect_type_list,
                version_limit_list: common.compare_type_list,
                channel_list: [],
                recommend: [{
                    type: 1,
                    name: '是'
                }, {
                    type: 2,
                    name: '否'
                }],
                is_version: [{
                    'type': 1,
                    'name': "显示"
                }, {
                    'type': 2,
                    'name': "隐藏"
                }],

                id: '',
                name: '',
                icon_url: '',
                redirect_type: '',
                android_redirect_url: '',
                ios_redirect_url: '',
                bind_params: '',
                title: '',
                weight: '',
                redirect_title: '',
                version_type: '',
                version: '',
                is_show: '2',
                is_recommend: '',
                channel_id: '',
                status: '1',
            }
        },
        watch: {
            platform(val) {
                if (val.id != undefined) {
                    this.id = val.id
                    this.name = val.name
                    this.icon_url = val.icon_url
                    this.redirect_type = val.redirect_type
                    this.android_redirect_url = val.android_redirect_url
                    this.ios_redirect_url = val.ios_redirect_url
                    this.bind_params = val.bind_params
                    this.title = val.title
                    this.weight = val.weight
                    this.redirect_title = val.redirect_title
                    this.version_type = val.version_type
                    this.version = val.version
                    this.is_show = Number(val.is_show)
                    this.is_recommend = Number(val.is_recommend)
                    this.channel_id = val.channel_id
                    this.status = Number(val.status)
                } else {
                    this.id = ''
                    this.name = ''
                    this.icon_url = ''
                    this.redirect_type = ''
                    this.android_redirect_url = ''
                    this.ios_redirect_url = ''
                    this.bind_params = ''
                    this.title = ''
                    this.weight = ''
                    this.redirect_title = ''
                    this.version_type = ''
                    this.version = ''
                    this.is_show = 2
                    this.is_recommend = 2
                    this.channel_id = ''
                    this.status = 1
                }
            },
            showExport(val) {
                this.dialog = val
            },
            dialog(val) {
                if (!val) {
                    this.close()
                }
            }
        },
        mounted() {
            this.channel()
        },
        methods: {
            // 获取频道
            channel() {
                api.getChannelClassifyList({
                    page: 1,
                    pagesize: 50,
                }, res => {
                    this.channel_list = res.data.list
                })
            },

            close() {
                this.$emit('clickClose', false)
            },
            imgUrl(val) {
                this.icon_url = val
            },
            // 添加修改
            primary() {
                if (this.id != '' && this.id != undefined) {
                    api.updateLifeChannelDetail({
                            id: this.id,
                            name: this.name,
                            icon_url: this.icon_url,
                            redirect_type: this.redirect_type,
                            android_redirect_url: this.android_redirect_url,
                            ios_redirect_url: this.ios_redirect_url,
                            bind_params: this.bind_params,
                            title: this.title,
                            weight: this.weight,
                            redirect_title: this.redirect_title,
                            version_type: this.version_type,
                            version: this.version,
                            is_show: this.is_show,
                            is_recommend: this.is_recommend,
                            channel_id: this.channel_id,
                            status: this.status,
                        },
                        res => {
                            this.$message({
                                message: '修改成功',
                                type: 'success'
                            })
                            this.$emit('clickClose', false)
                        })
                    return
                }
                api.addLifeChannelDetail({
                    name: this.name,
                    icon_url: this.icon_url,
                    redirect_type: this.redirect_type,
                    android_redirect_url: this.android_redirect_url,
                    ios_redirect_url: this.ios_redirect_url,
                    bind_params: this.bind_params,
                    title: this.title,
                    weight: this.weight,
                    redirect_title: this.redirect_title,
                    version_type: this.version_type,
                    version: this.version,
                    is_show: this.is_show,
                    is_recommend: this.is_recommend,
                    channel_id: this.channel_id,
                    status: this.status,
                }, res => {
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    })
                    this.$emit('clickClose', false)
                })
            },
        }
    }
</script>
<style scoped>
    .el-button {
        margin-top: 10px
    }
    
    .el-select {
        float: left;
    }
    
    .export-box {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .3);
        z-index: 20;
    }
    
    .title {
        margin-bottom: 20px;
        text-align: left;
        font-weight: bold
    }
</style>