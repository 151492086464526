import _import from './_import'

// 概述
export default [{
    path: '/',
    component: () =>
        import ('@/pages/public/index/index.vue'),
    children: [{
        path: 'assets/task',
        name: 'assetsTask',
        component: () =>
            import ('@/pages/assets/task/index.vue'),
        meta: {
            title: '任务管理'
        }
    }, {
        path: 'assets/record',
        name: 'assetsRecord',
        component: () =>
            import ('@/pages/assets/record/index.vue'),
        meta: {
            title: '免单兑换记录'
        }
    }]
}]