import _import from './_import'
// 仪表盘
export default [{
    path: '/',
    component: () =>
        import ('@/pages/public/index/index.vue'),
    children: [{
        path: 'MDMS/pid',
        name: 'MDMSPid',
        component: () =>
            import ('@/pages/MDMS/pid/index.vue'),
        meta: {
            title: '仪表盘平台渠道'
        },
    }]
}]